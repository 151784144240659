import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function ResetPassword({ backendURL }) {
  const queryParams = new URLSearchParams(window.location.search);
  const resetToken = queryParams.get("token");
  const userid = queryParams.get("userid");

  const [inputs, setInputs] = useState({
    userid,
    password: "",
  });

  const { password } = inputs;

  const history = useHistory();

  const onChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const body = { userid, password };

      const response = await fetch(`${backendURL}auth/resetpassword`, {
        method: "POST",
        headers: { "Content-Type": "application/json", token: resetToken },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();

      if (response.status === 200) {
        alert(
          `Wachtwoord succesvol aangepast, gebruik je nieuwe wachtwoord om in te loggen`
        );

        history.push({
          pathname: "/login",
          search: `?locatie=${inputs.locatie}&gemeente=${inputs.gemeente}&zaaknummer=${inputs.zaaknummer}`,
        });
      } else {
        toast.error(parseRes.message);
      }

      console.log(response);
    } catch (error) {}
  };

  return (
    <>
      <div className="row vertical-center">
        <div className="col-lg-6 offset-md-3 login-form">
          <div className="m-4">
            Vul hieronder het nieuwe wachtwoord in en klik op Verzenden.
          </div>
          <div className="m-4">
            <form onSubmit={onSubmitForm}>
              <input
                type="password"
                name="password"
                placeholder="Nieuw wachtwoord"
                className="form-control my-3"
                value={password}
                onChange={(e) => onChange(e)}
              ></input>
              <button
                className="btn btn-success btn-block btn-md mb-3 mt-3"
                type="submit"
              >
                Verzenden
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
