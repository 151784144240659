import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiInformationVariant, mdiKeyboardBackspace, mdiInformation } from "@mdi/js";
import InputMask from "react-input-mask";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { UserContext } from "../context/UserContext";

export default function BoaForm({ setAuth, backendURL, userInfo, curDate }) {
  const [userContext, setUserContext] = useContext(UserContext);
  const queryParams = new URLSearchParams(window.location.search);
  const mailToken = queryParams.get("token");
  const reportid = queryParams.get("reportid");

  const [showInfo1, setShowInfo1] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertChange, setAlertChange] = useState(true);
  const [escoreCalcTotal, setEscoreCalcTotal] = useState(0);
  const [gscoreCalcTotal, setGscoreCalcTotal] = useState(37.5);
  const [stratScoreCalcTotal, setStratScoreCalcTotal] = useState(0);
  const [G6G7Maxed, setG6G7Maxed] = useState(0);
  const [multipliers, setMultipliers] = useState("");
  const [gemeenten, setGemeenten] = useState([]);
  const [ernstq7aClass, setErnstq7aClass] = useState("");
  const [gedragq8Class, setGedragq8Class] = useState("");
  const [gedragq9Class, setGedragq9Class] = useState("");
  const [strat4Class, setStrat4Class] = useState("");
  const [gAfwijkingClass, setGAfwijkingClass] = useState("");
  const [eAfwijkingClass, setEAfwijkingClass] = useState("");
  const [disableSend, setDisableSend] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [sliderStyle, setSliderStyle] = useState({
    ernstq1: "form-control-range custom-range checked",
    ernstq2: "form-control-range custom-range checked",
    ernstq3: "form-control-range custom-range checked",
    ernstq4: "form-control-range custom-range checked",
    ernstq5: "form-control-range custom-range checked",
    ernstq6: "form-control-range custom-range checked",
    ernstq7: "form-control-range custom-range checked",
    ernstq8: "form-control-range custom-range checked",
    gedragq1: "form-control-range custom-range checked",
    gedragq2: "form-control-range custom-range checked",
    gedragq3: "form-control-range custom-range checked",
    gedragq4: "form-control-range custom-range checked",
    gedragq5: "form-control-range custom-range checked",
    gedragq6: "form-control-range custom-range checked",
    gedragq7: "form-control-range custom-range checked",
    strat1: "form-control-range custom-range",
    strat2: "form-control-range custom-range",
    strat3: "form-control-range custom-range",
    strat4: "form-control-range custom-range",
    strat5: "form-control-range custom-range",
    strat6: "form-control-range custom-range",
    strat7: "form-control-range custom-range",
  });
  const [rangeValueStyle, setRangeValueStyle] = useState({
    ernstq1: "range-value",
    ernstq2: "range-value",
    ernstq3: "range-value",
    ernstq4: "range-value",
    ernstq5: "range-value",
    ernstq6: "range-value",
    ernstq7: "range-value",
    ernstq8: "range-value",
    gedragq1: "range-value",
    gedragq2: "range-value",
    gedragq3: "range-value",
    gedragq4: "range-value",
    gedragq5: "range-value",
    gedragq6: "range-value",
    gedragq7: "range-value",
    strat1: "range-value-hidden",
    strat2: "range-value-hidden",
    strat3: "range-value-hidden",
    strat4: "range-value-hidden",
    strat5: "range-value-hidden",
    strat6: "range-value-hidden",
    strat7: "range-value-hidden",
  });
  const [inputs, setInputs] = useState({
    constDat: "",
    medewerker: "",
    locatie: "",
    zaaknummer: "",
    omschrijving: "",
    ernstq1: "",
    ernstq2: "",
    ernstq3: "",
    ernstq4: "",
    ernstq5: "",
    ernstq6: "",
    ernstq7: "",
    ernstq7a: "",
    ernstq7aTekst: "",
    ernstq8: "",
    gedragq1: "",
    gedragq2: "",
    gedragq3: "",
    gedragq4: "",
    gedragq5: "",
    gedragq6: "",
    gedragq7: "",
    gedragq8: "",
    gedragq8Tekst: "",
    gedragq9: "",
    gedragq9Tekst: "",
    gedragq10: "",
    E1Calc: "",
    E2Calc: "",
    E3Calc: "",
    E4Calc: "",
    E5Calc: "",
    E6Calc: "",
    E7Calc: "",
    E8Calc: "",
    E10Calc: "",
    G6Calc: "",
    G7Calc: "",
    G8Calc: "",
    G9Calc: "",
    G4G5Maxed: "",
    G4Toelichting: "",
    G6Toelichting: "",
    G10Calc: "",
    gAfwijking: "",
    gAfwijkingToelichting: "",
    eAfwijking: "",
    eAfwijkingToelichting: "",
    gemeente: "",
    gemeenteid: "",
    userid: "",
    organisationid: userInfo.organisationId,
    strat1: "",
    strat2: "",
    strat3: "",
    strat4tekst: "",
    strat5: "",
    strat6: "",
    strat7: "",
    rapportId: "",
    strat5Toelichting: "",
    strat_remarks: "",
  });

  const history = useHistory();
  const cancelReport = () => history.push("/boaformlist");
  const goToBoaFormList = () => history.push("/boaformlist");

  const handleCloseInfo1 = () => setShowInfo1(false);
  const handleShowInfo1 = (text) => {
    setInfoText(text);
    setShowInfo1(true);
  };

  const {
    constDat,
    medewerker,
    locatie,
    zaaknummer,
    omschrijving,
    ernstq1,
    ernstq2,
    ernstq3,
    ernstq4,
    ernstq5,
    ernstq6,
    ernstq7,
    ernstq7a,
    ernstq8,
    gedragq1,
    gedragq2,
    gedragq3,
    gedragq4,
    gedragq5,
    gedragq6,
    gedragq7,
    gedragq8,
    gedragq9,
    gedragq10,
    E1Calc,
    E3Calc,
    E5Calc,
    E7Calc,
    E8Calc,
    E10Calc,
    G1Calc,
    G2Calc,
    G3Calc,
    G4Calc,
    G5Calc,
    G6Calc,
    G7Calc,
    G8Calc,
    G9Calc,
    G4G5Maxed,
    G4Toelichting,
    G6Toelichting,
    G10Calc,
    gAfwijking,
    eAfwijking,
    eAfwijkingToelichting,
    gAfwijkingToelichting,
    gemeente,
    strat1,
    strat2,
    strat3,
    strat4tekst,
    strat5,
    strat6,
    strat7,
    strat1Calc,
    strat2Calc,
    strat3Calc,
    strat5Calc,
    strat6Calc,
    strat7Calc,
    strat5Toelichting,
    strat_remarks,
  } = inputs;

  const [eScoreCijfer, setEScoreCijfer] = useState("");
  const [gScoreLetter, setGScoreLetter] = useState("");

  const cancelGAfwijking = () => {
    setGAfwijkingClass("");
    setInputs({ ...inputs, gAfwijking: "", gAfwijkingToelichting: "" });
  };

  const cancelEAfwijking = () => {
    setEAfwijkingClass("");
    setInputs({ ...inputs, eAfwijking: "", eAfwijkingToelichting: "" });
  };

  // onderstaande functie wordt eenmalig aangeroepen bij het openen van de pagina.
  // deze haalt de bijbehorende multipliers op voor de juiste organisatie.
  const onOpenForm = async () => {
    // Wanneer het formulier geopend wordt via email link authenticatie hier regelen.
    if (mailToken !== null) {
      localStorage.setItem("token", mailToken);
      /*setUserContext((oldValues) => {
        return { ...oldValues, token: mailToken };
      });*/
    }

    const start = Date.now();

    setStartTime(start);

    const getReport = await fetch(`${backendURL}forms/getreport/${reportid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.token || userContext.token,
      },
    });

    const report = await getReport.json();

    let rawDate = new Date(report[0].datum);

    let dag_raw = rawDate.getDate();
    let maand_raw = rawDate.getMonth() + 1;
    let jaar = rawDate.getFullYear();
    let date, month;

    if (dag_raw < 10) {
      date = "0" + dag_raw.toString();
    } else {
      date = dag_raw.toString();
    }
    if (maand_raw < 10) {
      month = "0" + maand_raw.toString();
    } else {
      month = maand_raw.toString();
    }

    let constDate = jaar + "-" + month + "-" + date;

    const response = await fetch(`${backendURL}forms/multipliers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.token || userContext.token,
      },
    });

    const multip = await response.json();

    setMultipliers(multip);

    let G4G5MaxStartVal;

    if (report[0].score_multipl_g4 + report[0].score_multipl_g5 > 25) {
      G4G5MaxStartVal = 25;
    } else {
      G4G5MaxStartVal = report[0].score_multipl_g4 + report[0].score_multipl_g5;
    }

    switch (true) {
      case report[0].ernstq7atekst === "plantDier":
        setErnstq7aClass("plantDier");
        break;
      case report[0].ernstq7atekst === "mens":
        setErnstq7aClass("mens");
        break;
      case report[0].ernstq7atekst === "mensPlantDier":
        setErnstq7aClass("mensPlantDier");
        break;
      default:
        break;
    }

    switch (true) {
      case report[0].gscore_afwijken === "A":
        setGAfwijkingClass("gAfwA");
        break;
      case report[0].gscore_afwijken === "B":
        setGAfwijkingClass("gAfwB");
        break;
      case report[0].gscore_afwijken === "C":
        setGAfwijkingClass("gAfwC");
        break;
      case report[0].gscore_afwijken === "D":
        setGAfwijkingClass("gAfwD");
        break;
      default:
        break;
    }

    switch (true) {
      case report[0].escore_afwijken === "1":
        setEAfwijkingClass("eAfw1");
        break;
      case report[0].escore_afwijken === "2":
        setEAfwijkingClass("eAfw2");
        break;
      case report[0].escore_afwijken === "3":
        setEAfwijkingClass("eAfw3");
        break;
      case report[0].escore_afwijken === "4":
        setEAfwijkingClass("eAfw4");
        break;
      default:
        break;
    }

    let g8CalcStartVal;

    switch (true) {
      case report[0].gedragq8tekst === "legalJa":
        g8CalcStartVal = -25.0;
        setGedragq8Class("legalJa");
        break;
      case report[0].gedragq8tekst === "legalNee":
        g8CalcStartVal = 0;
        setGedragq8Class("legalNee");
        break;
      case report[0].gedragq8tekst === "legalNvt":
        g8CalcStartVal = 0;
        setGedragq8Class("legalNvt");
        break;
      default:
        break;
    }

    let g9Tekst, inputG9, inputG10, inputE9;

    switch (true) {
      case report[0].gedragq9tekst === "Nee":
        g9Tekst = "Nee";
        inputG9 = 0;
        inputG10 = 0;
        inputE9 = 0;
        setGedragq9Class("G9Nee");
        break;
      case report[0].gedragq9tekst === "JaEenmaal":
        g9Tekst = "JaEenmaal";
        inputG9 = 25;
        inputG10 = 0;
        inputE9 = 25;
        setGedragq9Class("G9JaEenmaal");
        break;
      case report[0].gedragq9tekst === "JaMeermaal":
        g9Tekst = "JaMeermaal";
        inputG9 = 25;
        inputG10 = 50;
        inputE9 = 25;
        setGedragq9Class("G9JaMeermaal");
        break;
      default:
        break;
    }

    setInputs({
      ...inputs,
      constDat: constDate,
      medewerker: report[0].medewerker,
      locatie: report[0].locatie,
      gemeente: report[0].gemeenteid,
      zaaknummer: report[0].zaaknummer,
      omschrijving: report[0].omschrijving,
      ernstq1: report[0].score_e1,
      ernstq2: report[0].score_e2,
      ernstq3: report[0].score_e3,
      ernstq4: report[0].score_e4,
      ernstq5: report[0].score_e5,
      ernstq6: report[0].score_e6,
      ernstq7: report[0].score_e7,
      ernstq7a: report[0].score_e7a,
      ernstq8: report[0].score_e8,
      gedragq1: report[0].score_g1,
      gedragq2: report[0].score_g2,
      gedragq3: report[0].score_g3,
      gedragq4: report[0].score_g4,
      gedragq5: report[0].score_g5,
      gedragq6: report[0].score_g6,
      gedragq7: report[0].score_g7,
      gedragq8: report[0].score_g8,
      ernstq7aTekst: report[0].ernstq7atekst,
      gedragq8Tekst: report[0].gedragq8tekst,
      E1Calc: report[0].score_multipl_e1,
      E2Calc: report[0].score_multipl_e2,
      E3Calc: report[0].score_multipl_e3,
      E4Calc: report[0].score_multipl_e4,
      E5Calc: report[0].score_multipl_e5,
      E6Calc: report[0].score_multipl_e6,
      E7Calc: report[0].score_multipl_e7,
      E8Calc: report[0].score_multipl_e8,
      G1Calc: report[0].score_multipl_g1,
      G2Calc: report[0].score_multipl_g2,
      G3Calc: report[0].score_multipl_g3,
      G4Calc: report[0].score_multipl_g4,
      G5Calc: report[0].score_multipl_g5,
      G6Calc: report[0].score_multipl_g6,
      G7Calc: report[0].score_multipl_g7,
      G4Toelichting: report[0].toelichting_e4,
      G6Toelichting: report[0].toelichting_e6,
      gAfwijking: report[0].gscore_afwijken,
      eAfwijking: report[0].escore_afwijken,
      eAfwijkingToelichting: report[0].escore_mutareden,
      gAfwijkingToelichting: report[0].gscore_mutareden,
      userid: report[0].userid,
      G4G5Maxed: G4G5MaxStartVal,
      gedragq9Tekst: g9Tekst,
      gedragq9: inputG9,
      G8Calc: g8CalcStartVal,
      G9Calc: inputG9,
      gedragq10: inputG10,
      G10Calc: inputG10,
      ernstq9: inputE9,
      E10Calc: inputE9,
      rapportId: reportid,
    });

    let G6G7MaxStartVal;

    if (inputG10 - inputE9 + report[0].score_multipl_g6 + report[0].score_multipl_g7 > 50) {
      G6G7MaxStartVal = 50;
    } else {
      G6G7MaxStartVal =
        report[0].score_multipl_g6 + report[0].score_multipl_g7 + inputG10 - 0.5 * inputG10;
    }

    setG6G7Maxed(G6G7MaxStartVal);

    setEscoreCalcTotal(report[0].escore_calc_total * 1);
    setGscoreCalcTotal(report[0].gscore_calc_total * 1);

    setGedragq8Class(report[0].gedragq8tekst);
    setGedragq9Class(`G9${report[0].gedragq9tekst}`);

    setGScoreLetter(report[0].gscore_letter);
    setEScoreCijfer(report[0].escore_cijfer);

    const getGemeenten = await fetch(`${backendURL}forms/gemeenten`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.token || userContext.token,
      },
    });

    const resultGemeenten = await getGemeenten.json();

    setGemeenten(resultGemeenten);
  };

  const onChange = (e, targetName) => {
    const boaFields = [
      "strat1",
      "strat2",
      "strat3",
      "strat4",
      "strat5",
      "strat6",
      "strat7",
      "strat5Toelichting",
      "strat_remarks",
    ];
    if (alertChange === true && !boaFields.includes(e.target.name)) {
      alert(
        `Deze antwoorden zijn door ${medewerker} ter plaatse vastgelegd, verander deze alleen als je zeker weet dat ze onjuist zijn.`
      );
      setAlertChange(false);
    }
    let inputValue, inputG9, inputG10, inputE9, maxed, g9Tekst;

    switch (targetName) {
      case "strat4":
        switch (e.target.id) {
          case "strat4Ja":
            setStrat4Class("strat4Ja");
            setInputs({
              ...inputs,
              strat4tekst: "Ja",
            });
            break;
          case "strat4Nee":
            setStrat4Class("strat4Nee");
            setInputs({
              ...inputs,
              strat4tekst: "Nee",
            });
            break;
          case "strat4Onbekend":
            setStrat4Class("strat4Onbekend");
            setInputs({
              ...inputs,
              strat4tekst: "Onbekend",
            });
            break;
          default:
            break;
        }
        break;
      case "gAfwijking":
        switch (e.target.id) {
          case "gAfwA":
            setGAfwijkingClass("gAfwA");
            setInputs({
              ...inputs,
              gAfwijking: "A",
            });
            break;
          case "gAfwB":
            setGAfwijkingClass("gAfwB");
            setInputs({
              ...inputs,
              gAfwijking: "B",
            });
            break;
          case "gAfwC":
            setGAfwijkingClass("gAfwC");
            setInputs({
              ...inputs,
              gAfwijking: "C",
            });
            break;
          case "gAfwD":
            setGAfwijkingClass("gAfwD");
            setInputs({
              ...inputs,
              gAfwijking: "D",
            });
            break;
          default:
            break;
        }
        break;
      case "eAfwijking":
        switch (e.target.id) {
          case "eAfw1":
            setEAfwijkingClass("eAfw1");
            setInputs({
              ...inputs,
              eAfwijking: "1",
            });
            break;
          case "eAfw2":
            setEAfwijkingClass("eAfw2");
            setInputs({
              ...inputs,
              eAfwijking: "2",
            });
            break;
          case "eAfw3":
            setEAfwijkingClass("eAfw3");
            setInputs({
              ...inputs,
              eAfwijking: "3",
            });
            break;
          case "eAfw4":
            setEAfwijkingClass("eAfw4");
            setInputs({
              ...inputs,
              eAfwijking: "4",
            });
            break;
          default:
            break;
        }
        break;
      case "ernstq7a":
        switch (e.target.id) {
          case "plantDier":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              ernstq7a: 0.3,
              ernstq7aTekst: "plantDier",
              E7Calc:
                ((inputs.E6Calc * 1 * (0.3 * 1) * (inputs.ernstq7 * 1)) / 10) * multipliers.E7,
            });
            setErnstq7aClass("plantDier");
            break;
          case "mens":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              ernstq7a: 1.0,
              ernstq7aTekst: "mens",
              E7Calc:
                ((inputs.E6Calc * 1 * (1.0 * 1) * (inputs.ernstq7 * 1)) / 10) * multipliers.E7,
            });
            setErnstq7aClass("mens");
            break;
          case "mensPlantDier":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              ernstq7a: 1.0,
              ernstq7aTekst: "mensPlantDier",
              E7Calc:
                ((inputs.E6Calc * 1 * (1.0 * 1) * (inputs.ernstq7 * 1)) / 10) * multipliers.E7,
            });
            setErnstq7aClass("mensPlantDier");
            break;
          default:
            break;
        }

        break;
      case "gedragq8":
        switch (e.target.id) {
          case "legalJa":
            inputValue = -25.0;
            setGedragq8Class("legalJa");
            break;
          case "legalNee":
            inputValue = 0;
            setGedragq8Class("legalNee");
            break;
          case "legalNvt":
            inputValue = 0;
            setGedragq8Class("legalNvt");
            break;
          default:
            break;
        }
        setInputs({
          ...inputs,
          gedragq8Tekst: e.target.id,
          [e.target.name]: inputValue,
          G8Calc: inputValue,
        });
        break;
      case "gedragq9":
        switch (e.target.id) {
          case "G9Nee":
            g9Tekst = "Nee";
            inputG9 = 0;
            inputG10 = 0;
            inputE9 = 0;
            setGedragq9Class("G9Nee");
            break;
          case "G9JaEenmaal":
            g9Tekst = "JaEenmaal";
            inputG9 = 25;
            inputG10 = 0;
            inputE9 = 25;
            setGedragq9Class("G9JaEenmaal");
            break;
          case "G9JaMeermaal":
            g9Tekst = "JaMeermaal";
            inputG9 = 25;
            inputG10 = 50;
            inputE9 = 25;
            setGedragq9Class("G9JaMeermaal");
            break;
          default:
            break;
        }
        setInputs({
          ...inputs,
          gedragq9Tekst: g9Tekst,
          gedragq9: inputG9,
          G9Calc: inputG9,
          gedragq10: inputG10,
          G10Calc: inputG10,
          ernstq9: inputE9,
          E10Calc: inputE9,
        });

        break;
      default:
        switch (e.target.id) {
          case "ernstq1":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E1Calc: ((e.target.value * 1) / 10) * multipliers.E1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq2":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E2Calc: ((e.target.value * 1) / 10) * multipliers.E2,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq3":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E3Calc: inputs.E2Calc * (((e.target.value * 1) / 10) * multipliers.E3),
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq4":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E4Calc: ((e.target.value * 1) / 10) * multipliers.E4,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq5":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E5Calc: inputs.E4Calc * ((e.target.value * 1) / 10) * multipliers.E5,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq6":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E6Calc: ((e.target.value * 1) / 10) * multipliers.E6,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq7":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E7Calc:
                ((inputs.E6Calc * 1 * (inputs.ernstq7a * 1) * (e.target.value * 1)) / 10) *
                multipliers.E7,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "ernstq8":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              E8Calc: ((e.target.value * 1) / 10) * multipliers.E8,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq1":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G1Calc:
                (((e.target.value * 1) / 10) * multipliers.G1 * escoreCalcTotal).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq2":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G2Calc: (((e.target.value * 1) / 10) * multipliers.G2 * G1Calc).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq3":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G3Calc: (((e.target.value * 1) / 10) * multipliers.G3).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq4":
            if (((e.target.value * 1) / 10) * multipliers.G4 + G5Calc * 1 > 25) {
              maxed = 25;
            } else {
              maxed = (((e.target.value * 1) / 10) * multipliers.G4 + G5Calc).toFixed(1) * 1;
            }
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G4Calc: (((e.target.value * 1) / 10) * multipliers.G4).toFixed(1) * 1,
              G4G5Maxed: maxed,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "G4Toelichting":
            setInputs({ ...inputs, [e.target.name]: e.target.value });
            break;
          case "gedragq5":
            if (((e.target.value * 1) / 10) * multipliers.G5 + G4Calc * 1 > 25) {
              maxed = 25;
            } else {
              maxed = (((e.target.value * 1) / 10) * multipliers.G5 + G4Calc).toFixed(1) * 1;
            }
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G5Calc: (((e.target.value * 1) / 10) * multipliers.G5).toFixed(1) * 1,
              G4G5Maxed: maxed,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq6":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G6Calc: (((e.target.value * 1) / 10) * multipliers.G6).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "gedragq7":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              G7Calc: (((e.target.value * 1) / 10) * multipliers.G7).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "strat1":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              strat1Calc: (((e.target.value * 1) / 10) * multipliers.S1).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "strat2":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              strat2Calc: (((e.target.value * 1) / 10) * multipliers.S2).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "strat3":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              strat3Calc: (((e.target.value * 1) / 10) * multipliers.S3).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "strat5":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              strat5Calc: (((e.target.value * 1) / 10) * multipliers.S5).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "strat6":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              strat6Calc: (((e.target.value * 1) / 10) * multipliers.S6).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          case "strat7":
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
              strat7Calc: (((e.target.value * 1) / 10) * multipliers.S7).toFixed(1) * 1,
            });
            setSliderStyle({
              ...sliderStyle,
              [e.target.name]: "form-control-range custom-range checked",
            });
            setRangeValueStyle({
              ...rangeValueStyle,
              [e.target.name]: "range-value",
            });
            break;
          default:
            setInputs({
              ...inputs,
              [e.target.name]: e.target.value,
            });
            break;
        }
        break;
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const endTime = Date.now();

      inputs.escoreTotal = escoreCalcTotal;
      inputs.gscoreTotal = gscoreCalcTotal;
      inputs.gscoreLetter = gScoreLetter;
      inputs.escoreCijfer = eScoreCijfer;
      inputs.startTijd = startTime;
      inputs.eindTijd = endTime;
      inputs.stratScoreTotal = stratScoreCalcTotal;

      const response = await fetch(`${backendURL}forms/boaform`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify(inputs),
      });

      if (response.status === 200) {
        alert(`Rapport indienden geslaagd.\nEmail verstuurd naar het bij ons bekende email adres`);

        history.push({
          pathname: "/",
        });
      } else {
        alert("Er is een fout opgetreden probeer het opnieuw");
      }
    } catch (error) {
      console.log(error);
    }
  };

  let noernstq7a;
  useEffect(() => {
    if (ernstq6 === 0) {
      noernstq7a = true;
    }

    if (
      ernstq1 !== "" &&
      ernstq2 !== "" &&
      ernstq3 !== "" &&
      ernstq4 !== "" &&
      ernstq5 !== "" &&
      ernstq6 !== "" &&
      ernstq7 !== "" &&
      (ernstq7a !== "" || noernstq7a === true) &&
      ernstq8 !== ""
    ) {
      setEscoreCalcTotal((E1Calc + E3Calc + E5Calc + E7Calc) * E8Calc + E10Calc);
      switch (true) {
        case escoreCalcTotal >= 0 && escoreCalcTotal < 25:
          setEScoreCijfer(1);
          break;
        case escoreCalcTotal >= 25 && escoreCalcTotal < 50:
          setEScoreCijfer(2);
          break;
        case escoreCalcTotal >= 50 && escoreCalcTotal < 75:
          setEScoreCijfer(3);
          break;
        case escoreCalcTotal >= 75:
          setEScoreCijfer(4);
          break;
        default:
          break;
      }
    }
  }, [E1Calc, E3Calc, E5Calc, E7Calc, E8Calc, E10Calc]);

  useEffect(() => {
    setInputs({
      ...inputs,
      G1Calc: (((gedragq1 * 1) / 10) * multipliers.G1 * escoreCalcTotal).toFixed(1) * 1,
    });
  }, [
    G2Calc,
    G3Calc,
    G4G5Maxed,
    G6G7Maxed,
    G8Calc,
    G9Calc,
    G10Calc,
    E10Calc,
    escoreCalcTotal,
    G1Calc,
  ]);

  useEffect(() => {
    setInputs({
      ...inputs,
      G2Calc: (((gedragq2 * 1) / 10) * multipliers.G2 * G1Calc).toFixed(1) * 1,
    });
  }, [G1Calc]);

  let toelAfwG = true;
  let toelAfwE = true;

  // Check invoer afwijkende waarden
  useEffect(() => {
    if (
      gAfwijking.length > 0 &&
      (gAfwijkingToelichting === undefined || gAfwijkingToelichting === "")
    ) {
      toelAfwG = false;
      //setDisableSend(true);
    }
    if (
      eAfwijking.length > 0 &&
      (eAfwijkingToelichting === undefined || eAfwijkingToelichting === "")
    ) {
      toelAfwE = false;
      //setDisableSend(true);
    }

    if (toelAfwG === true && toelAfwE === true) {
      //setDisableSend(false);
    }
  }, [gAfwijking, eAfwijking, gAfwijkingToelichting, eAfwijkingToelichting, toelAfwG, toelAfwE]);

  // Check invoer velden stap3 (gedrag)
  let toelG4 = true;
  let toelG6 = true;

  // G6 + G7 maxed bepalen
  useEffect(() => {
    let value =
      G10Calc - E10Calc + G6Calc + G7Calc > 50 ? 50 : G6Calc + G7Calc + G10Calc - 0.5 * G10Calc;
    setG6G7Maxed(value);
  }, [G10Calc, E10Calc, G6Calc, G7Calc, inputs]);

  // Gedrag totaal score bepalen
  useEffect(() => {
    if (
      gedragq1 !== "" &&
      gedragq2 !== "" &&
      gedragq3 !== "" &&
      gedragq4 !== "" &&
      gedragq5 !== "" &&
      gedragq6 !== "" &&
      gedragq7 !== "" &&
      gedragq8 !== "" &&
      gedragq9 !== "" &&
      gedragq10 !== "" &&
      toelG4 === true &&
      toelG6 === true
    ) {
      setGscoreCalcTotal(37.5 + G2Calc + G3Calc + G4G5Maxed + G6G7Maxed + G8Calc + G9Calc);
      switch (true) {
        case gscoreCalcTotal > 0 && gscoreCalcTotal < 25:
          setGScoreLetter("A");
          break;
        case gscoreCalcTotal >= 25 && gscoreCalcTotal < 50:
          setGScoreLetter("B");
          break;
        case gscoreCalcTotal >= 50 && gscoreCalcTotal < 75:
          setGScoreLetter("C");
          break;
        case gscoreCalcTotal >= 75:
          setGScoreLetter("D");
          break;
        default:
          break;
      }
    }
  }, [
    G2Calc,
    G3Calc,
    G4G5Maxed,
    G6G7Maxed,
    G8Calc,
    G9Calc,
    G10Calc,
    E10Calc,
    G4Toelichting,
    G6Toelichting,
    gedragq1,
    gedragq2,
    gedragq3,
    gedragq4,
    gedragq5,
    gedragq6,
    gedragq7,
    gedragq8,
    gedragq9,
    gedragq10,
  ]);

  useEffect(() => {
    if (
      constDat !== "" &&
      medewerker !== "" &&
      locatie !== "" &&
      zaaknummer !== "" &&
      omschrijving !== "" &&
      ernstq1 !== "" &&
      ernstq2 !== "" &&
      ernstq3 !== "" &&
      ernstq4 !== "" &&
      ernstq5 !== "" &&
      ernstq6 !== "" &&
      ernstq7 !== "" &&
      ernstq7a !== "" &&
      ernstq8 !== "" &&
      gedragq1 !== "" &&
      gedragq2 !== "" &&
      gedragq3 !== "" &&
      gedragq4 !== "" &&
      gedragq5 !== "" &&
      gedragq6 !== "" &&
      gedragq7 !== "" &&
      gedragq8 !== "" &&
      gedragq9 !== "" &&
      gedragq10 !== "" &&
      E1Calc !== "" &&
      E3Calc !== "" &&
      E5Calc !== "" &&
      E7Calc !== "" &&
      E8Calc !== "" &&
      E10Calc !== "" &&
      G1Calc !== "" &&
      G2Calc !== "" &&
      G3Calc !== "" &&
      G4Calc !== "" &&
      G5Calc !== "" &&
      G7Calc !== "" &&
      G6Calc !== "" &&
      G8Calc !== "" &&
      G9Calc !== "" &&
      G4G5Maxed !== "" &&
      G10Calc !== "" &&
      gemeente !== "" &&
      strat1 !== "" &&
      strat2 !== "" &&
      strat3 !== "" &&
      strat4tekst !== "" &&
      strat5 !== "" &&
      strat6 !== "" &&
      strat7 !== "" &&
      strat1Calc !== "" &&
      strat2Calc !== "" &&
      strat3Calc !== "" &&
      strat5Calc !== "" &&
      strat6Calc !== "" &&
      strat7Calc !== ""
    ) {
      setDisableSend(false);
    }
    if (
      (gAfwijking !== "" && gAfwijkingToelichting === "") ||
      (eAfwijking !== "" && eAfwijkingToelichting === "")
    ) {
      setDisableSend(true);
    }
    if (gedragq4 * 1 > 50 && G4Toelichting === "") {
      setDisableSend(true);
    }
    if (gedragq6 * 1 > 50 && G6Toelichting === "") {
      setDisableSend(true);
    }
  }, [inputs]);

  // Strategie totaal bepalen
  useEffect(() => {
    setStratScoreCalcTotal(
      strat1Calc + strat2Calc + strat3Calc + strat5Calc + strat6Calc + strat7Calc
    );
  }, [strat1, strat2, strat3, strat5, strat6, strat7]);

  useEffect(() => {
    onOpenForm();
  }, []);

  return (
    <>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button className="btn btn-outline-secondary btn-md" onClick={goToBoaFormList}>
            <Icon path={mdiKeyboardBackspace} size={1} /> Rapportages te beoordelen
          </button>
        </div>
      </div>
      <div className="container d-flex justify-content-center">
        <div className="col-md-10 login-form my-3">
          <form onSubmit={onSubmitForm}>
            <h3 className="text-right my-5 h5">MeldingsID {reportid}</h3>
            <h2 className="text-center my-5 h3">Algemene gegevens</h2>
            <div className="form-group row">
              <label htmlFor="constDat" className="col-sm-4 col-form-label">
                Datum constatering
                <a
                  className="modal-info-tip"
                  /*data-tooltip="De datum waarop de vastgelegde situatie werd waargenomen"*/
                  /*className="toolTipRight"*/
                  onClick={() =>
                    handleShowInfo1("De datum waarop de vastgelegde situatie werd waargenomen")
                  }
                >
                  <Icon path={mdiInformationVariant} size={0.9} />
                </a>
              </label>
              <div className="col-sm-8">
                <input
                  className="form-control"
                  name="constDat"
                  value={constDat}
                  onChange={(e) => onChange(e)}
                  type="date"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="userId" className="col-sm-4 col-form-label">
                Medewerker
              </label>
              <div className="col-sm-8">
                <input
                  name="medewerker"
                  value={medewerker}
                  onChange={(e) => onChange(e)}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <h2 className="text-center my-5">De overtreding zelf</h2>
            <div className="form-group row">
              <div className="form-group col-md-12 col-sm-12 col-lg-6">
                <label htmlFor="locatie">Op welke locatie is de overtreding geconstateerd?</label>
                <input
                  className="form-control"
                  name="locatie"
                  value={locatie}
                  onChange={(e) => onChange(e)}
                  disabled
                />
              </div>
              <div className="form-group col-md-6 col-sm-6 col-lg-3">
                <label htmlFor="gemeente">Gemeente</label>
                <select
                  className="form-control"
                  value={gemeente}
                  name="gemeente"
                  id="gemeente"
                  onChange={(e) => onChange(e)}
                  disabled
                >
                  <option value=""></option>
                  {gemeenten.map((gemeente) => (
                    <option value={gemeente.pkey} key={gemeente.pkey}>
                      {gemeente.gemeente}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6 col-sm-6 col-lg-3">
                <label htmlFor="zaaknummer">Zaaknummer</label>
                <InputMask
                  mask="z-99-999999-99"
                  maskChar=" "
                  onChange={(e) => onChange(e)}
                  name="zaaknummer"
                  value={zaaknummer}
                  placeholder="z-______-___"
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="omschrijving">
                  Geef een bondige omschrijving van de geconstateerde overtreding (max. 400 tekens)
                </label>
                <textarea
                  name="omschrijving"
                  value={omschrijving}
                  onChange={(e) => onChange(e)}
                  className="form-control"
                  rows="3"
                  disabled
                />
              </div>
            </div>
            <h2 className="text-center my-5 h3">
              De vragen (ernst)
              <a
                className="modal-info-tip"
                onClick={() =>
                  handleShowInfo1(
                    "In de onderstaande vragen gaat het om de schade die is ontstaan of nog kan ontstaan op het moment van de controle.  Onder 'schade' wordt verstaan het ontstaan van maatschappelijke onrust en/of feitelijke schade aan milieu, natuur of water en/of aan mens, dier of plant."
                  )
                }
              >
                <Icon path={mdiInformationVariant} size={0.9} />
              </a>
            </h2>
            <h3 className="my-2 h4">Schade aan milieu, water en/of natuur</h3>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="ernstq1">
                  Wat is de omvang van de <u>al onstane</u> mileu/water en natuurschade?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Nihil</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block mt-4">Nihil</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq1} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq1}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq1"
                        value={ernstq1 === "" ? 0 : ernstq1}
                        className={sliderStyle.ernstq1}
                        id="ernstq1"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block mt-4">Enorm</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label htmlFor="ernstq2">
                  Hoe groot is de kans dat er alsnog of grotere schade ontstaat?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">0%</div>
                  <div className="ml-auto d-flex justify-content-end my-1">100%</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">0%</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq2} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq2}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq2"
                        value={ernstq2 === "" ? 0 : ernstq2}
                        className={sliderStyle.ernstq2}
                        id="ernstq2"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">100%</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label>
                  <u>Als</u> er nog extra schade ontstaat hoe groot zal die dan waarschijnlijk zijn?
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "Let op het gaat er niet om óf de schade ontstaat maar hoe groot de schade zal zijn áls deze ontstaat"
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>
                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Nihil</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq3} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq3}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq3"
                        value={ernstq3 === "" ? 0 : ernstq3}
                        className={sliderStyle.ernstq3}
                        id="ernstq3"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                </div>
              </div>
            </div>
            <h3 className="my-2 h4">Maatschappelijke onrust</h3>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label>
                  Als er schade is of komt, hoe groot is dan de kans dat dit maatschappelijke onrust
                  oplevert?
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "Denk aan demonstraties, media aandacht, paniek, verlies van vertrouwen in overheid"
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">0%</div>
                  <div className="ml-auto d-flex justify-content-end my-1">100% / Die is er al</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">0%</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq4} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq4}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq4"
                        value={ernstq4 === "" ? 0 : ernstq4}
                        className={sliderStyle.ernstq4}
                        id="ernstq4"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">100% / Die is er al</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label htmlFor="ernstq5">
                  <u>Als</u> er onrust komt, hoe groot zal die dan zijn?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Nihil</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq5} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq5}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq5"
                        value={ernstq5 === "" ? 0 : ernstq5}
                        className={sliderStyle.ernstq5}
                        id="ernstq5"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                </div>
              </div>
            </div>
            <h3 className="my-2 h4">Doden, zieken of gewonden</h3>
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label htmlFor="ernstq6">
                  Hoe groot is de kans dat mensen, dieren of planten gewond raken, ziek worden of
                  sterven?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">0%</div>
                  <div className="ml-auto d-flex justify-content-end my-1">100% / Dat is al zo</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">0%</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq6} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq6}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq6"
                        value={ernstq6 === "" ? 0 : ernstq6}
                        className={sliderStyle.ernstq6}
                        id="ernstq6"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">100% / Dat is al zo</div>
                </div>
              </div>
            </div>
            {ernstq6 !== "" && ernstq6 > 0 && (
              <>
                <p>Het betreft dan:</p>
                <div className="form-group row">
                  <div className="form-group col-sm-12">
                    <input
                      type="radio"
                      className="btn-check"
                      name="ernstq7a"
                      id="plantDier"
                      autoComplete="off"
                      hidden
                      onClick={(e) => onChange(e, "ernstq7a")}
                    />
                    <label
                      className={`btn ${
                        ernstq7aClass === "plantDier" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="plantDier"
                    >
                      Plant en dier
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="ernstq7a"
                      id="mens"
                      autoComplete="off"
                      hidden
                      onClick={(e) => onChange(e, "ernstq7a")}
                    />
                    <label
                      className={`btn ${
                        ernstq7aClass === "mens" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="mens"
                    >
                      Mens
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="ernstq7a"
                      id="mensPlantDier"
                      autoComplete="off"
                      hidden
                      onClick={(e) => onChange(e, "ernstq7a")}
                    />
                    <label
                      className={`btn ${
                        ernstq7aClass === "mensPlantDier" ? "btn-success" : "btn-outline-secondary"
                      } col-sm-4`}
                      htmlFor="mensPlantDier"
                    >
                      Mens, plant en dier
                    </label>
                  </div>
                </div>
              </>
            )}
            <div className="form-group row">
              <div className="form-group col-md-12">
                <label>
                  <u>Als</u> dat gebeurt, wat is dan de meest waarschijnlijke omvang (aantallen en
                  ernst) daarvan?
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "Wanneer de overtreder vindt dat de gevolgen van de overtreding beperkt zijn dan is dat onjuist"
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Nihil</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Nihil</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq7} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq7}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq7"
                        value={ernstq7 === "" ? 0 : ernstq7}
                        className={sliderStyle.ernstq7}
                        id="ernstq7"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Enorm</div>
                </div>
              </div>
            </div>
            <h3 className="my-2 h4">Tenslotte: ongedaan maken van schade?</h3>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="ernstq8">Kan de schade hersteld worden?</label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Volledig</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Helemaal niet</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Volledig</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.ernstq8} id="rangeV" style={{ right: 12 }}>
                        <span>{ernstq8}</span>
                      </div>
                      <input
                        type="range"
                        name="ernstq8"
                        value={ernstq8 === "" ? 0 : ernstq8}
                        className={sliderStyle.ernstq8}
                        id="ernstq8"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Helemaal niet</div>
                </div>
              </div>
            </div>
            <h2 className="text-center my-5 h3">De vragen (gedrag)</h2>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq1">
                  Hoe voorspelbaar was het op voorhand, voor jou als deskundige, dat er schade zou
                  ontstaan?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Geheel onwaarschijnlijk</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Enorm</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Geheel onwaarschijnlijk</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq1} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq1}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq1"
                        value={gedragq1 === "" ? 0 : gedragq1}
                        className={sliderStyle.gedragq1}
                        id="gedragq1"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Nagenoeg zeker</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq2">
                  Had van de overtreder verwacht mogen worden dat deze dezelfde inschatting zou
                  maken?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq2} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq2}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq2"
                        value={gedragq2 === "" ? 0 : gedragq2}
                        className={sliderStyle.gedragq2}
                        id="gedragq2"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq3">
                  Wist de overtreder al dat er sprake was van een overtreding?
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker wel</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker niet</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq3} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq3}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq3"
                        value={gedragq3 === "" ? 0 : gedragq3}
                        className={sliderStyle.gedragq3}
                        id="gedragq3"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq4">
                  De overtreder heeft de overtreding begaan om voordeel te behalen.
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq4} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq4}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq4"
                        value={gedragq4 === "" ? 0 : gedragq4}
                        className={sliderStyle.gedragq4}
                        id="gedragq4"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            {G4Calc >= 8.8 && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <textarea
                    name="G4Toelichting"
                    value={G4Toelichting}
                    id="G4Toelichting"
                    placeholder="Geef aan welke indicaties je hebt"
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq5">
                  De overtreder probeerde te voorkomen dat de overtreding werd gezien.
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq5} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq5}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq5"
                        value={gedragq5 === "" ? 0 : gedragq5}
                        className={sliderStyle.gedragq5}
                        id="gedragq5"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq6">
                  Het bedrijf is opgezet om de wet te overtreden (criminele organisatie).
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq6} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq6}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq6"
                        value={gedragq6 === "" ? 0 : gedragq6}
                        className={sliderStyle.gedragq6}
                        id="gedragq6"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            {G6Calc > 17.5 && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <textarea
                    name="G6Toelichting"
                    value={G6Toelichting}
                    id="G6Toelichting"
                    placeholder="Geef aan welke indicaties je hebt"
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label htmlFor="gedragq7">
                  Meerdere handhavende instanties treden op in de richting van dit bedrijf.
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.gedragq7} id="rangeV" style={{ right: 12 }}>
                        <span>{gedragq7}</span>
                      </div>
                      <input
                        type="range"
                        name="gedragq7"
                        value={gedragq7 === "" ? 0 : gedragq7}
                        className={sliderStyle.gedragq7}
                        id="gedragq7"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <h2 className="text-center my-5 h3">De omstandigheden (verzachtend / verzwarend)</h2>
            <p>Is legalisatie van de overtreding een mogelijkheid?</p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq8"
                  id="legalJa"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gedragq8")}
                  onClick={(e) => onChange(e, "gedragq8")}
                />
                <label
                  className={`btn ${
                    gedragq8Class === "legalJa" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="legalJa"
                >
                  Ja
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq8"
                  id="legalNee"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gedragq8")}
                  onClick={(e) => onChange(e, "gedragq8")}
                />
                <label
                  className={`btn ${
                    gedragq8Class === "legalNee" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="legalNee"
                >
                  Nee
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq8"
                  id="legalNvt"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gedragq8")}
                  onClick={(e) => onChange(e, "gedragq8")}
                />
                <label
                  className={`btn ${
                    gedragq8Class === "legalNvt" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="legalNvt"
                >
                  NVT
                </label>
              </div>
            </div>
            <p>Is dezelfde overtreding al eerder geconstateerd bij dit bedrijf?</p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq9"
                  id="G9Nee"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gedragq9")}
                />
                <label
                  className={`btn ${
                    gedragq9Class === "G9Nee" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="G9Nee"
                >
                  Nee
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq9"
                  id="G9JaEenmaal"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gedragq9")}
                />
                <label
                  className={`btn ${
                    gedragq9Class === "G9JaEenmaal" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="G9JaEenmaal"
                >
                  Ja eenmaal
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gedragq9"
                  id="G9JaMeermaal"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gedragq9")}
                />
                <label
                  className={`btn ${
                    gedragq9Class === "G9JaMeermaal" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-4`}
                  htmlFor="G9JaMeermaal"
                >
                  Ja meermalen
                </label>
              </div>
            </div>
            <h2 className="text-center my-5 h3">Het resultaat</h2>
            <p>
              Volgens de gehanteerde methodiek dient de overtreding gekwalificeerd te worden in
              categorie:{" "}
              <b>
                {gScoreLetter}
                {eScoreCijfer} (G: {gscoreCalcTotal.toFixed(2)}, E: {escoreCalcTotal.toFixed(2)}){" "}
              </b>
              <br />
              Als je dat niet vindt, geeft dan hier aan waar je afwijkt van de scores.
            </p>
            <p>Gedrag</p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="gAfwijking"
                  id="gAfwA"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gAfwijking")}
                  onClick={(e) => onChange(e, "gAfwijking")}
                  disabled={gScoreLetter !== "A" ? false : true}
                />
                <label
                  className={`btn ${
                    gAfwijkingClass === "gAfwA" ? "btn-success" : "btn-outline-secondary"
                  } ${gScoreLetter === "A" ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="gAfwA"
                >
                  A
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gAfwijking"
                  id="gAfwB"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gAfwijking")}
                  onClick={(e) => onChange(e, "gAfwijking")}
                  disabled={gScoreLetter !== "B" ? false : true}
                />
                <label
                  className={`btn ${
                    gAfwijkingClass === "gAfwB" ? "btn-success" : "btn-outline-secondary"
                  } ${gScoreLetter === "B" ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="gAfwB"
                >
                  B
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gAfwijking"
                  id="gAfwC"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gAfwijking")}
                  onClick={(e) => onChange(e, "gAfwijking")}
                  disabled={gScoreLetter !== "C" ? false : true}
                />
                <label
                  className={`btn ${
                    gAfwijkingClass === "gAfwC" ? "btn-success" : "btn-outline-secondary"
                  } ${gScoreLetter === "C" ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="gAfwC"
                >
                  C
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="gAfwijking"
                  id="gAfwD"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "gAfwijking")}
                  onClick={(e) => onChange(e, "gAfwijking")}
                  disabled={gScoreLetter !== "D" ? false : true}
                />
                <label
                  className={`btn ${
                    gAfwijkingClass === "gAfwD" ? "btn-success" : "btn-outline-secondary"
                  } ${gScoreLetter === "D" ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="gAfwD"
                >
                  D
                </label>
              </div>
            </div>
            {gAfwijking !== "" && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <label htmlFor="gAfwijkingToelichting">
                    Wat is doorslaggevend om <br className="d-block d-sm-none" />
                    tot de inschatting {gAfwijking} te komen?
                  </label>
                  <textarea
                    name="gAfwijkingToelichting"
                    value={gAfwijkingToelichting}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    rows="3"
                  />
                </div>
                <div className="row col-md-6 offset-md-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-block"
                    onClick={cancelGAfwijking}
                  >
                    Annuleer afwijkende gedrag score
                  </button>
                </div>
              </div>
            )}
            <p>Ernst</p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="eAfwijking"
                  id="eAfw1"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "eAfwijking")}
                  onClick={(e) => onChange(e, "eAfwijking")}
                  disabled={eScoreCijfer != 1 ? false : true}
                />
                <label
                  className={`btn ${
                    eAfwijkingClass === "eAfw1" ? "btn-success" : "btn-outline-secondary"
                  } ${eScoreCijfer == 1 ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="eAfw1"
                >
                  1
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="eAfwijking"
                  id="eAfw2"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "eAfwijking")}
                  onClick={(e) => onChange(e, "eAfwijking")}
                  disabled={eScoreCijfer != 2 ? false : true}
                />
                <label
                  className={`btn ${
                    eAfwijkingClass === "eAfw2" ? "btn-success" : "btn-outline-secondary"
                  } ${eScoreCijfer == 2 ? "btn-secondary" : ""}  col-sm-3`}
                  htmlFor="eAfw2"
                >
                  2
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="eAfwijking"
                  id="eAfw3"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "eAfwijking")}
                  onClick={(e) => onChange(e, "eAfwijking")}
                  disabled={eScoreCijfer != 3 ? false : true}
                />
                <label
                  className={`btn ${
                    eAfwijkingClass === "eAfw3" ? "btn-success" : "btn-outline-secondary"
                  } ${eScoreCijfer == 3 ? "btn-secondary" : ""} col-sm-3`}
                  htmlFor="eAfw3"
                >
                  3
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="eAfwijking"
                  id="eAfw4"
                  autoComplete="off"
                  hidden
                  onChange={(e) => onChange(e, "eAfwijking")}
                  onClick={(e) => onChange(e, "eAfwijking")}
                  disabled={eScoreCijfer != 4 ? false : true}
                />
                <label
                  className={`btn ${
                    eAfwijkingClass === "eAfw4" ? "btn-success" : "btn-outline-secondary"
                  }  col-sm-3 ${eScoreCijfer == 4 ? "btn-secondary" : ""}`}
                  htmlFor="eAfw4"
                >
                  4
                </label>
              </div>
            </div>
            {eAfwijking !== "" && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <label htmlFor="eAfwijkingToelichting">
                    Wat is doorslaggevend om <br className="d-block d-sm-none" /> tot de inschatting{" "}
                    {eAfwijking} te komen?
                  </label>
                  <textarea
                    name="eAfwijkingToelichting"
                    value={eAfwijkingToelichting}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    rows="3"
                  />
                </div>
                <div className="row col-md-6 offset-md-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-block"
                    onClick={cancelEAfwijking}
                  >
                    Annuleer afwijkende ernst score
                  </button>
                </div>
              </div>
            )}
            <h2 className="text-center my-5 h3">Over de te kiezen strategie</h2>
            <p>
              <b>
                Geef hieronder aan of en in welke mate er sprake is van één of meerdere van
                onderstaande verzwarende aspecten.
              </b>
            </p>
            <br /> <br />
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label>
                  Het is waarschijnlijk dat met de overtreding financieel voordeel (winst of
                  besparing) werd verkegen
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "Door het (nalaten van) handelen is financieel voordeel behaald of financieel voordeel halen was het doel daarvan."
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Geheel niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Geheel niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.strat1} id="rangeV" style={{ right: 12 }}>
                        <span>{strat1}</span>
                      </div>
                      <input
                        type="range"
                        name="strat1"
                        value={strat1 === "" ? 0 : strat1}
                        className={sliderStyle.strat1}
                        id="strat1"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label>
                  De overtreder vervult een bijzondere positie (voorbeeldfunctie/media/politieke
                  gevoeligheid)
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "De overtreder is: een regionaal of landelijk maatschappelijk aansprekende of bekende (rechts)persoon, een overheid, een toonaangevend brancheonderdeel, een certificerende instelling, een persoon die een openbaar ambt bekleedt of de eigen organisatie."
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Geheel niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Geheel niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.strat2} id="rangeV" style={{ right: 12 }}>
                        <span>{strat2}</span>
                      </div>
                      <input
                        type="range"
                        name="strat2"
                        value={strat2 === "" ? 0 : strat2}
                        className={sliderStyle.strat2}
                        id="strat2"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label>
                  Zal een financiële sanctie het beoogde effect sorteren?
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "Wanneer een bestuurlijke boete zeker niet geïnd kan worden of zeer waarschijnlijk door de overtreder als (bedrijfs)kosten is ingecalculeerd kies je voor 'zeker niet'"
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker wel</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker niet</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.strat3} id="rangeV" style={{ right: 12 }}>
                        <span>{strat3}</span>
                      </div>
                      <input
                        type="range"
                        name="strat3"
                        value={strat3 === "" ? 0 : strat3}
                        className={sliderStyle.strat3}
                        id="strat3"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                </div>
              </div>
            </div>
            <p>
              Los van gedragingen tijdens de controle door de toezichthouder heeft de overtreder ook
              andere handelingen gepleegd ter verhulling van de feiten, zoals valsheid in geschrift,
              corruptie of witwassen.
            </p>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <input
                  type="radio"
                  className="btn-check"
                  name="strat4"
                  id="strat4Nee"
                  autoComplete="off"
                  hidden
                  onClick={(e) => onChange(e, "strat4")}
                />
                <label
                  className={`btn ${
                    strat4Class === "strat4Nee" ? "btn-success" : "btn-outline-secondary"
                  } col-sm-4`}
                  htmlFor="strat4Nee"
                >
                  Nee
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="strat4"
                  id="strat4Ja"
                  autoComplete="off"
                  hidden
                  onClick={(e) => onChange(e, "strat4")}
                />
                <label
                  className={`btn ${
                    strat4Class === "strat4Ja" ? "btn-success" : "btn-outline-secondary"
                  } col-sm-4`}
                  htmlFor="strat4Ja"
                >
                  Ja
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="strat4"
                  id="strat4Onbekend"
                  autoComplete="off"
                  hidden
                  onClick={(e) => onChange(e, "strat4")}
                />
                <label
                  className={`btn ${
                    strat4Class === "strat4Onbekend" ? "btn-success" : "btn-outline-secondary"
                  } col-sm-4`}
                  htmlFor="strat4Onbekend"
                >
                  Onbekend
                </label>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label>
                  Er is aantoonbaar hulp ingeroepen van 3-den om de overtreding mogelijk te maken
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "De overtreder is bij zijn handelen ondersteund door deskundige derden, zoals vergunningverlenende of certificerende instellingen, keuringinstanties en brancheorganisaties. De handhaver moet onderbouwen op grond van welke aanwijzingen hij het vermoeden heeft dat de deskundige derde op de hoogte was en/of medewerking heeft verleend aan de geconstateerde bevinding(en)."
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Zeker niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Zeker niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.strat5} id="rangeV" style={{ right: 12 }}>
                        <span>{strat5}</span>
                      </div>
                      <input
                        type="range"
                        name="strat5"
                        value={strat5 === "" ? 0 : strat5}
                        className={sliderStyle.strat5}
                        id="strat5"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            {strat5 >= 50 && (
              <div className="form-group row">
                <div className="form-group col-sm-12">
                  <textarea
                    name="strat5Toelichting"
                    value={strat5Toelichting}
                    id="strat5Toelichting"
                    placeholder="Geef hier aan waaruit de kan worden afgeleid:"
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label>
                  Het is nodig om met handhaving een voorbeeld te stellen voor andere (potentiele)
                  overtreders
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "Het doel van de handhaving ligt in het onder de aandacht brengen van het belang van een bepaalde norm bij de branche of bij het bredere publiek. Strafrechtelijke handhaving vindt mede plaats in het kader van normhandhaving of normbevestiging met het oog op grotere achterliggende te beschermen rechtsbelangen. Hierbij speelt de openbaarheid van het strafproces een grote rol. Als in het openbaar, door middel van een onderzoek ter terechtzitting of de publicatie van een persbericht bij een transactie of strafbeschikking, verantwoording wordt afgelegd van gepleegde strafbare feiten krijgt de normhandhaving of normbevestiging het juiste effect."
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Geheel niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Geheel niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.strat6} id="rangeV" style={{ right: 12 }}>
                        <span>{strat6}</span>
                      </div>
                      <input
                        type="range"
                        name="strat6"
                        value={strat6 === "" ? 0 : strat6}
                        className={sliderStyle.strat6}
                        id="strat6"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-sm-12">
                <label>
                  Strafrechtelijke handhaving zou waarschijnlijk relevante extra feiten naar boven
                  brengen
                  <a
                    className="modal-info-tip"
                    onClick={() =>
                      handleShowInfo1(
                        "Strafrechtelijk optreden met toepassing van opsporingsbevoegdheden met het oog op de strafrechtelijke waarheidsvinding en afdoening is gewenst als bijvoorbeeld een controle of inspectie aanwijzingen aan het licht kan brengen dat er meer aan de hand is, maar de bestuursrechtelijke instrumenten ontoereikend zijn om de waarheid aan het licht te brengen."
                      )
                    }
                  >
                    <Icon path={mdiInformationVariant} size={0.9} />
                  </a>
                </label>

                {/* alleen op mobiel labels erboven tonen */}
                <div className="d-flex flex-row d-block d-sm-none">
                  <div className="d-flex justify-content-start my-1">Geheel niet</div>
                  <div className="ml-auto d-flex justify-content-end my-1">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-2 d-none d-sm-block">Geheel niet</div>
                  <div className="col-sm-8">
                    <div className="range-wrap">
                      <div className={rangeValueStyle.strat7} id="rangeV" style={{ right: 12 }}>
                        <span>{strat7}</span>
                      </div>
                      <input
                        type="range"
                        name="strat7"
                        value={strat7 === "" ? 0 : strat7}
                        className={sliderStyle.strat7}
                        id="strat7"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                        onTouchStart={(e) => onChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-2 d-none d-sm-block">Zeker wel</div>
                </div>

                <div className="form-group row">
                  <div className="form-group col-sm-12 pt-3">
                    <label htmlFor="omschrijving">Opmerkingen / conclusies:</label>
                    <textarea
                      name="strat_remarks"
                      value={strat_remarks === "" ? "" : strat_remarks}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              className={
                disableSend === false
                  ? "btn btn-success btn-block btn-lg my-4"
                  : "btn btn-secondary btn-block btn-lg my-4"
              }
              type="submit"
              disabled={disableSend}
            >
              Verzenden
            </button>
          </form>
        </div>
      </div>

      <div className="d-flex flex-row d-block d-block d-sm-none">
        <div className="col-md-10 d-flex justify-content-center">
          <div className="nav-light mt-3">
            <button
              type="button"
              className="btn btn-outline-danger btn-block btn-md mt-2 mb-4"
              onClick={cancelReport}
            >
              Beoordeling annuleren
            </button>
          </div>
        </div>
      </div>

      <div className="row col-md-10 offset-md-1 d-none d-sm-block">
        <div className="row col-md-6 offset-md-3">
          <button
            type="button"
            className="btn btn-outline-danger btn-block my-5"
            onClick={cancelReport}
          >
            Beoordeling annuleren
          </button>
        </div>
      </div>

      <Modal show={showInfo1} onHide={handleCloseInfo1} keyboard={true} centered>
        <Modal.Header>
          <Modal.Title>
            <Icon path={mdiInformation} color="#006fb4" size={1.6} />
            <span className="glyphtext-right">{"  "}Informatie</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="form-group row">
              <div className="col-12">{infoText}</div>
            </div>
            <div className="form-group row"></div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary btn-sm" onClick={handleCloseInfo1}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
