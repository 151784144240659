import React, { Fragment, useContext, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Online } from "react-detect-offline";

import { UserContext } from "../../context/UserContext";

import "./style.css";

const Home = ({ backendURL, boaItemCount, setBoaItemCount }) => {
  const [userContext, setUserContext] = useContext(UserContext);

  const fetchUserDetails = useCallback(async () => {
    const response = await fetch(`${backendURL}user/me`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
    });

    if (response.status === 200) {
      const data = await response.json();

      if (data.role === "boa") {
        const boaCount = await fetch(`${backendURL}forms/getboaformcount`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: userContext.token,
          },
        });

        let boaItemCount = await boaCount.json();
        data.boaItemCount = boaItemCount[0].count;
      }

      //TODO checken welke data nodig is voor offline gebruik, de rest eruit flikkeren
      localStorage.setItem("userinfo", JSON.stringify(data));

      setUserContext((oldValues) => {
        return { ...oldValues, details: data };
      });

      const getGemeenten = await fetch(`${backendURL}forms/gemeenten`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      });

      const resultGemeenten = await getGemeenten.json();
      localStorage.setItem("gemeenten", JSON.stringify(resultGemeenten));

      const getMultipliers = await fetch(`${backendURL}forms/multipliers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      });

      const resultMultipliers = await getMultipliers.json();
      localStorage.setItem("multipliers", JSON.stringify(resultMultipliers));
    } else {
      if (response.status === 401) {
        window.location.reload();
      } else {
        setUserContext((oldValues) => {
          return { ...oldValues, details: null };
        });
      }
    }
  }, [backendURL, setUserContext, userContext.token]);

  const logoutHandler = async () => {
    try {
      const response = await fetch(`${backendURL}auth/logout`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      });

      if (response.ok) {
        setUserContext((oldValues) => {
          return { ...oldValues, details: undefined, token: null };
        });
        localStorage.setItem("logout", Date.now());
      }
    } catch (error) {}
  };

  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details) {
      fetchUserDetails();
    }
  }, [userContext.details, fetchUserDetails]);

  const history = useHistory();
  const handleClick = () => history.push("/form");
  const goToBoaFormList = () => history.push("/boaformlist");
  const goToUsers = () => history.push("/users");
  const goToDashboard = () => history.push("/dashboard");
  const goToDataDownload = () => history.push("/download");
  const goToSettings = () => history.push("/settings");
  const goToMaintenanceReportList = () =>
    history.push("/maintenancereportlist");

  return (
    <Fragment>
      <div className="col-md-10 offset-md-1 my-2">
        <h1 className="text-center my-5 h6">
          <i>
            Welkom{" "}
            {userContext.details
              ? userContext.details.firstname +
                " " +
                userContext.details.lastname
              : ""}
          </i>
        </h1>

        {["user", "admin"].includes(userContext.details?.role) && (
          <>
            <div className="nav-light">
              <button
                type="button"
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={handleClick}
              >
                Nieuw rapport aanmaken
              </button>
            </div>
          </>
        )}

        {["admin", "superMario"].includes(userContext.details?.role) && (
          <>
            <div className="nav-light mt-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={goToDashboard}
              >
                Dashboard
              </button>
            </div>

            <div className="nav-light mt-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={goToUsers}
              >
                Gebruikers
              </button>
            </div>
          </>
        )}

        {["admin", "superMario"].includes(userContext.details?.role) && (
          <>
            <div className="nav-light mt-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={goToSettings}
              >
                Instellingen
              </button>
            </div>
          </>
        )}

        {["admin", "superMario"].includes(userContext.details?.role) && (
          <>
            <div className="nav-light mt-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={goToDataDownload}
              >
                Gegevens Downloaden
              </button>
            </div>
          </>
        )}

        {["admin", "superMario"].includes(userContext.details?.role) && (
          <>
            <div className="nav-light mt-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={goToMaintenanceReportList}
              >
                Beheer Rapporten
              </button>
            </div>
          </>
        )}

        {["boa"].includes(userContext.details?.role) && (
          <>
            <div className="nav-light mt-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={goToBoaFormList}
              >
                Rapportages te beoordelen{" "}
                <span className="badge bg-danger text-light">
                  {userContext.details.boaItemCount}
                </span>
              </button>
            </div>
          </>
        )}

        <Online>
          <div className="nav-light mt-3">
            <button
              className="btn btn-outline-danger btn-block btn-lg"
              onClick={logoutHandler}
            >
              Uitloggen
            </button>
          </div>
        </Online>
      </div>
    </Fragment>
  );
};

export default Home;
