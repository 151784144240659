import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function LostPassword({ backendURL }) {
  const history = useHistory();
  const [inputs, setInputs] = useState({
    email: "",
  });
  const { email } = inputs;

  const onChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSubmitForm = async (e) => {
    try {
      e.preventDefault();
      console.log("Start aanvraag nieuw wachtwoord..");

      const body = { email };

      const response = await fetch(`${backendURL}auth/lost-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (response.status === 200) {
        history.push({ pathname: "confirm-reset-password" });
      }

      if (response.status !== 200) {
        const parseRes = await response.json();
        toast.error(parseRes.message);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="row vertical-center">
        <div className="col-lg-6 offset-md-3 login-form">
          <div className="m-5">
            Weet je het wachtwoord niet meer? Vul hieronder je e-mailadres in.
            We sturen dan binnen enkele minuten een e-mail waarmee een nieuw
            wachtwoord kan worden aangemaakt.
          </div>
          <div className="m-5">
            <form onSubmit={onSubmitForm}>
              <input
                type="email"
                name="email"
                placeholder="email@domein.com"
                className="form-control my-3"
                value={email}
                onChange={(e) => onChange(e)}
              ></input>
              <button
                className="btn btn-success btn-block btn-md mb-3 mt-3"
                type="submit"
              >
                Verzenden
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
