import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHome } from "@mdi/js";
import "../dashboard.css";

import { UserContext } from "../context/UserContext";

export default function Dashboard({ backendURL, userInfo, curDate }) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [scoreCard, setScoreCard] = useState({
    A1: 0,
    A2: 0,
    A3: 0,
    A4: 0,
    B1: 0,
    B2: 0,
    B3: 0,
    B4: 0,
    C1: 0,
    C2: 0,
    C3: 0,
    C4: 0,
    D1: 0,
    D2: 0,
    D3: 0,
    D4: 0,
  });
  const [prevScoreCard, setPrevScoreCard] = useState({
    A1: 0,
    A2: 0,
    A3: 0,
    A4: 0,
    B1: 0,
    B2: 0,
    B3: 0,
    B4: 0,
    C1: 0,
    C2: 0,
    C3: 0,
    C4: 0,
    D1: 0,
    D2: 0,
    D3: 0,
    D4: 0,
  });
  const [peilDatum, setPeilDatum] = useState(curDate);
  const [gemeenten, setGemeenten] = useState([]);
  const [gebruikers, setGebruikers] = useState([]);
  const [organisaties, setOrganisaties] = useState([]);
  const [gemeente, setGemeente] = useState("");
  const [organisatie, setOrganisatie] = useState("");
  const [gebruiker, setGebruiker] = useState("");

  let history = useHistory();

  async function getScoreCard(peilDatum, gemeente, userid, organisatie) {
    try {
      let reqUrl = `${backendURL}dashboard/getdashboard?peildatum=${peilDatum}`;
      if (gemeente && gemeente != "Alles" && gemeente !== "Niet gevonden") {
        reqUrl += `&gemeente=${gemeente}`;
      }
      if (userid && userid !== "Alles") {
        reqUrl += `&userid=${userid}`;
      }
      if (organisatie && organisatie != "Alles") {
        reqUrl += `&organisatie=${organisatie}`;
      }

      const response = await fetch(reqUrl, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setScoreCard(parseRes);
    } catch (err) {
      console.log(err);
    }
  }

  async function getPrevScoreCard(peilDatum, gemeente, userid, organisatie) {
    try {
      let prevPeildatum = new Date(peilDatum);

      prevPeildatum.setDate(prevPeildatum.getDate() - 90);

      let date_raw = prevPeildatum.getDate();
      let month_raw = prevPeildatum.getMonth() + 1;
      let year = prevPeildatum.getFullYear();

      var date, month;

      if (date_raw < 10) {
        date = "0" + date_raw.toString();
      } else {
        date = date_raw.toString();
      }
      if (month_raw < 10) {
        month = "0" + month_raw.toString();
      } else {
        month = month_raw.toString();
      }

      prevPeildatum = year + "-" + month + "-" + date;

      let reqUrl = `${backendURL}dashboard/getdashboard?peildatum=${prevPeildatum}`;
      if (gemeente && gemeente != "Alles" && gemeente !== "Niet gevonden") {
        reqUrl += `&gemeente=${gemeente}`;
      }
      if (userid && userid !== "Alles") {
        reqUrl += `&userid=${userid}`;
      }
      if (organisatie && organisatie != "Alles") {
        reqUrl += `&organisatie=${organisatie}`;
      }

      const response = await fetch(reqUrl, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setPrevScoreCard(parseRes);
    } catch (err) {
      console.log(err);
    }
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case "peilDatum":
        setPeilDatum(e.target.value);
        break;
      case "gemeente":
        setGemeente(e.target.value);
        break;
      case "organisatie":
        setOrganisatie(e.target.value);
        break;
      case "gebruiker":
        setGebruiker(e.target.value);
        break;
      default:
        break;
    }
  };

  // onderstaande functie wordt eenmalig aangeroepen bij het openen van de pagina.
  const onOpenDashboard = async () => {
    const getGemeenten = await fetch(`${backendURL}forms/gemeenten`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
    });

    const resultGemeenten = await getGemeenten.json();

    setGemeenten(resultGemeenten);

    const getGebruikers = await fetch(`${backendURL}admin/getusers`, {
      method: "GET",
      headers: { token: userContext.token },
    });

    const resultGebruikers = await getGebruikers.json();

    setGebruikers(resultGebruikers);

    if (userContext.details.role === "superMario") {
      const getOrganisaties = await fetch(
        `${backendURL}supermario/getorganisations`,
        {
          method: "GET",
          headers: { token: userContext.token },
        }
      );

      const resultOrganisaties = await getOrganisaties.json();

      setOrganisaties(resultOrganisaties);
    }
  };

  async function getGemeentenByOrg() {
    const getGemeenten = await fetch(
      `${backendURL}forms/gemeenten?organisatie=${organisatie}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      }
    );

    const resultGemeenten = await getGemeenten.json();

    if (resultGemeenten.length > 0) {
      setGemeenten(resultGemeenten);
    } else {
      setGemeenten([]);
    }
  }

  async function getGebruikersByOrg() {
    const getGebruikers = await fetch(
      `${backendURL}admin/getusers?organisatie=${organisatie}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      }
    );

    const resultGebruikers = await getGebruikers.json();

    if (resultGebruikers.length > 0) {
      setGebruikers(resultGebruikers);
    } else {
      setGebruikers([]);
    }
  }

  useEffect(() => {
    getScoreCard(peilDatum, gemeente, gebruiker, organisatie);
  }, [peilDatum, gemeente, gebruiker, organisatie]);

  useEffect(() => {
    getPrevScoreCard(peilDatum, gemeente, gebruiker, organisatie);
  }, [peilDatum, gemeente, gebruiker, organisatie]);

  useEffect(() => {
    getGemeentenByOrg();
    getGebruikersByOrg();
    setGemeente("");
    setGebruiker("");
  }, [organisatie]);

  useEffect(() => {
    onOpenDashboard();
  }, []);

  return (
    <Fragment>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={history.goBack}
          >
            <Icon path={mdiHome} size={1} />
          </button>
          {["superMario"].includes(userContext.details.role) && (
            <>
              <div>
                <div
                  htmlFor="organisatie"
                  className="btn btn-success btn-md peildatum-btn"
                >
                  Organisatie:{" "}
                  <select
                    value={organisatie}
                    name="organisatie"
                    id="organisatie"
                    onChange={(e) => onChange(e)}
                  >
                    <option value="Alles">Alles</option>
                    {organisaties.map((organisatie) => (
                      <option value={organisatie.pkey} key={organisatie.pkey}>
                        {organisatie.orgnaam}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="row">
          <br />
        </div>
      </div>

      <div className="col-md-12 dashboard-container my-3">
        <div className="row justify-content-between">
          <div
            className="col-md-6"
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "25px",
            }}
          >
            <h2 className="dashboard-header">
              Organisatie: {userContext.details.organisationName}
            </h2>

            <h4 className="dashboard-header">
              Periode: {scoreCard.startDate} t/m {scoreCard.peilDatum}
            </h4>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-4"
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            <div htmlFor="peilDatum">
              Peildatum:{" "}
              <input
                name="peilDatum"
                value={peilDatum}
                style={{
                  border: "none",
                  cursor: "pointer",
                }}
                onChange={(e) => onChange(e)}
                type="date"
              />
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            <div htmlFor="gemeente">
              Gemeente:{" "}
              <select
                value={gemeente}
                name="gemeente"
                id="gemeente"
                onChange={(e) => onChange(e)}
              >
                <option value="Alles">Alle gemeentes</option>
                {gemeenten.map((gemeente) => (
                  <option value={gemeente.pkey} key={gemeente.pkey}>
                    {gemeente.gemeente}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            <div htmlFor="peilDatum" className="">
              Gebruiker:{" "}
              <select
                value={gebruiker}
                name="gebruiker"
                id="gebruiker"
                onChange={(e) => onChange(e)}
              >
                <option value="Alles">Iedereen</option>
                {gebruikers.map((gebruiker) => (
                  <option value={gebruiker.pkey} key={gebruiker.pkey}>
                    {gebruiker.firstname + " " + gebruiker.lastname}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-6 primary-background"
            style={{ padding: "20px" }}
          >
            <div className="rectangle-left">
              <div className="content">
                <div className="table-cell-left">
                  <p>4</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.A4}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.A4}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.B4}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.B4}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.C4}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.C4}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.D4}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.D4}</p>
                </div>
              </div>
            </div>
            <div className="rectangle-left">
              <div className="content">
                <div className="table-cell-left">
                  <p>3</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.A3}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.A3}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.B3}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.B3}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.C3}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.C3}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.D3}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.D3}</p>
                </div>
              </div>
            </div>
            <div className="rectangle-left">
              <div className="content">
                <div className="table-cell-left">
                  <p>2</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.A2}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.A2}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.B2}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.B2}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.C2}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.C2}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.D2}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.D2}</p>
                </div>
              </div>
            </div>
            <div className="rectangle-left">
              <div className="content">
                <div className="table-cell-left">
                  <p>1</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.A1}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.A1}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.B1}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.B1}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.C1}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.C1}</p>
                </div>
              </div>
            </div>
            <div className="square">
              <div className="content">
                <div className="table-cell">
                  {scoreCard.D1}
                  <br />
                  <p className="table-cell-prev">{prevScoreCard.D1}</p>
                </div>
              </div>
            </div>
            <div className="rectangle-left">
              <div className="content">
                <div className="table-cell-left"></div>
              </div>
            </div>
            <div className="square-bottom">
              <div className="content">
                <div className="table-cell">A</div>
              </div>
            </div>
            <div className="square-bottom">
              <div className="content">
                <div className="table-cell">B</div>
              </div>
            </div>
            <div className="square-bottom">
              <div className="content">
                <div className="table-cell">C</div>
              </div>
            </div>
            <div className="square-bottom">
              <div className="content">
                <div className="table-cell">D</div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 primary-background"
            style={{ padding: "20px" }}
          >
            <div className="row">
              <div className="col" style={{ paddingLeft: "20px" }}>
                <p className="indicatoren-header">
                  Andere indicatoren deze periode
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ paddingLeft: "20px" }}>
                <p className="indicatoren">
                  {scoreCard.total} geregistreerde overtredingen
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ paddingLeft: "20px" }}>
                <p className="indicatoren">{scoreCard.recidiven}% recidiven</p>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ paddingLeft: "20px" }}>
                <p className="indicatoren">
                  {scoreCard.gAfwijk}% met afwijking op Gedrag-score
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ paddingLeft: "20px" }}>
                <p className="indicatoren">
                  {scoreCard.eAfwijk}% met afwijking op Ernst-score
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12 primary-background"
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          ></div>
        </div>
      </div>
    </Fragment>
  );
}
