import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiKeyboardBackspace } from "@mdi/js";

export default function Settings() {
  let history = useHistory();

  return (
    <Fragment>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={history.goBack}
          >
            <Icon path={mdiKeyboardBackspace} size={1} />
          </button>
        </div>
        <div className="col-md-10 offset-md-1 my-2"></div>
      </div>
    </Fragment>
  );
}
