import React, { Fragment, useEffect, useState, useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiDelete,
  mdiHome,
  mdiRefresh,
  mdiCheckboxOutline,
  mdiCheckboxBlankOutline,
} from "@mdi/js";
import { toast } from "react-toastify";

import { UserContext } from "../context/UserContext";

export default function Users({ backendURL, userInfo }) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [organisations, setOrganisations] = useState(["test"]);
  const [showVerifyDelete, setShowVerifyDelete] = useState(false);
  const [showInsertUser, setShowInsertUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [updatePassword, setUpdatePassword] = useState("");
  const [newUser, setNewUser] = useState({
    sex: "",
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    role: "",
    organisationId: "",
  });
  const [editUser, setEditUser] = useState({
    sex: "",
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    role: "",
    organisationId: "",
  });

  const handleClose = () => setShowVerifyDelete(false);
  const handleShowDeleteUser = (e, pkey) => {
    setShowVerifyDelete(true);
    setSelectedUser(pkey);
  };

  const handleCloseUpdatePassword = () => setShowUpdatePassword(false);

  const handleShowUpdatePassword = (e) => {
    e.preventDefault();
    handleCloseEditUser();
    setShowUpdatePassword(true);
  };

  // Nieuw wachtwoord aanvragen
  const onSubmitNewPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${backendURL}user/newpass`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify({ pkey: selectedUser, password: updatePassword }),
      });

      const parseRes = await response.json();

      if (response.status === 200) {
        handleCloseUpdatePassword();
        getUsers();
        setUpdatePassword("");
        toast.success(parseRes.message);
      } else {
        toast.error(parseRes.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowInsertUser = () => setShowInsertUser(true);
  const handleCloseInsertUser = () => {
    setShowInsertUser(false);
    setNewUser("");
  };

  const handleShowEditUser = async (e, pkey) => {
    setShowEditUser(true);
    setSelectedUser(pkey);

    const response = await fetch(`${backendURL}admin/getuser/${pkey}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
    });

    const parseRes = await response.json();

    setEditUser({
      pkey: parseRes[0].pkey,
      sex: parseRes[0].sex,
      firstName: parseRes[0].firstname,
      lastName: parseRes[0].lastname,
      email: parseRes[0].email,
      username: parseRes[0].username,
      role: parseRes[0].role,
      organisationId: parseRes[0].organisationid,
    });
  };

  const handleCloseEditUser = () => {
    setShowEditUser(false);
    setEditUser("");
  };

  const toggleActivateUser = async (e, pkey, inactive) => {
    let statusupdate;

    if (inactive === true) {
      statusupdate = true;
    } else {
      statusupdate = false;
    }

    const response = await fetch(
      `${backendURL}admin/activateuser/${statusupdate}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify({ pkey: pkey }),
      }
    );

    const parseRes = await response.json();

    if (response.status === 200) {
      getUsers();
    } else {
      toast.error(parseRes.message);
    }
  };

  const deleteUser = async () => {
    const response = await fetch(`${backendURL}admin/deleteuser`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
      body: JSON.stringify({ pkey: selectedUser }),
    });

    const parseRes = await response.json();

    if (response.status === 200) {
      handleCloseInsertUser();
      handleClose();
      getUsers();
      toast.success(parseRes.message);
    } else {
      toast.error(parseRes.message);
    }
  };

  let history = useHistory();

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm mr-1"
          onClick={(e) => handleShowEditUser(e, row.pkey)}
          id={row.pkey}
        >
          <Icon path={mdiPencil} size={1} />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={(e) => handleShowDeleteUser(e, row.pkey)}
        >
          <Icon path={mdiDelete} size={1} id={row.pkey} />
        </button>
      </>
    );
  };

  const activateUser = (cell, row) => {
    let iconSettings = {};
    if (
      row.inactive === false ||
      row.inactive === null ||
      row.inactive === undefined
    ) {
      iconSettings.path = mdiCheckboxOutline;
      iconSettings.class = "btn btn-outline-success btn-sm mr-1";
      iconSettings.color = "green";
    } else {
      iconSettings.path = mdiCheckboxBlankOutline;
      iconSettings.class = "btn btn-outline-danger btn-sm mr-1";
      iconSettings.color = "red";
    }
    return (
      <>
        <button
          className={iconSettings.class}
          onClick={(e) => toggleActivateUser(e, row.pkey, row.inactive)}
          id={row.pkey}
        >
          <Icon path={iconSettings.path} color={iconSettings.color} size={1} />
        </button>
      </>
    );
  };

  // Gebruikers ophalen
  async function getUsers() {
    try {
      const response = await fetch(`${backendURL}admin/getusers`, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setUsers(parseRes);
      //localStorage.setItem("users", JSON.stringify(parseRes));
    } catch (error) {
      //TODO WAAROM???
      //let collection = localStorage.getItem("users");
      //setUsers(JSON.parse(collection));
    }
  }

  // Organisaties Ophalen
  async function getOrganisations() {
    try {
      // extra verificatie op rol superMario
      if (userInfo.role !== "superMario") {
        return;
      }

      const response = await fetch(`${backendURL}supermario/getorganisations`, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setOrganisations(parseRes);
    } catch (error) {}
  }

  // Formulier versturen nieuwe user
  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendURL}admin/createuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify(newUser),
      });

      const parseRes = await response.json();

      if (response.status === 200) {
        handleCloseInsertUser();
        getUsers();
        setNewUser("");
        toast.success(parseRes.message);
      } else {
        toast.error(parseRes.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Formulier input controleren
  const onChange = (e) => {
    switch (e.target.id) {
      case "newUsername":
        setNewUser({
          ...newUser,
          username: e.target.value,
        });
        break;
      case "newPassword":
        setNewUser({
          ...newUser,
          password: e.target.value,
        });
        break;
      case "newEmail":
        setNewUser({
          ...newUser,
          email: e.target.value,
        });
        break;
      case "newLastName":
        setNewUser({
          ...newUser,
          lastName: e.target.value,
        });
        break;
      case "newFirstName":
        setNewUser({
          ...newUser,
          firstName: e.target.value,
        });
        break;
      case "newMaleFemale":
        setNewUser({
          ...newUser,
          sex: e.target.value,
        });
        break;
      case "newRole":
        setNewUser({
          ...newUser,
          role: e.target.value,
        });
        break;
      case "newOrganisation":
        setNewUser({
          ...newUser,
          organisation: e.target.value,
        });
        break;
      case "updatePassword":
        setUpdatePassword(e.target.value);
        break;
      default:
        break;
    }
  };

  // Formulier versturen
  const onSubmitEditForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendURL}admin/updateuser`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify(editUser),
      });

      const parseRes = await response.json();

      if (response.status === 200) {
        handleCloseEditUser();
        getUsers();
        setEditUser("");
        toast.success(parseRes.message);
      } else {
        toast.error(parseRes.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Formulier input controleren
  const onChangeEdit = (e) => {
    switch (e.target.id) {
      case "editUsername":
        setEditUser({
          ...editUser,
          username: e.target.value,
        });
        break;
      case "editPassword":
        setEditUser({
          ...editUser,
          password: e.target.value,
        });
        break;
      case "editEmail":
        setEditUser({
          ...editUser,
          email: e.target.value,
        });
        break;
      case "editLastName":
        setEditUser({
          ...editUser,
          lastName: e.target.value,
        });
        break;
      case "editFirstName":
        setEditUser({
          ...editUser,
          firstName: e.target.value,
        });
        break;
      case "editMaleFemale":
        setEditUser({
          ...editUser,
          sex: e.target.value,
        });
        break;
      case "editRole":
        setEditUser({
          ...editUser,
          role: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  // Datatable kolommen
  const columns = [
    {
      dataField: "username",
      text: "Gebruikersnaam",
      sort: true,
    },
    {
      dataField: "role",
      text: "Toegang",
      sort: true,
    },
    {
      dataField: "activate",
      text: "Actief",
      formatter: activateUser,
      dummy: true,
    },
    {
      dataField: "actions",
      text: "",
      csvExport: false,
      dummy: true,
      formatter: actionsFormatter,
    },
  ];

  const columnsSuper = [
    {
      dataField: "username",
      text: "Gebruikersnaam",
      sort: true,
    },
    {
      dataField: "organisation",
      text: "Organisatie",
      sort: true,
    },
    {
      dataField: "role",
      text: "Toegang",
      sort: true,
    },
    {
      dataField: "activate",
      text: "Actief",
      formatter: activateUser,
      dummy: true,
    },
    {
      dataField: "actions",
      text: "",
      csvExport: false,
      dummy: true,
      formatter: actionsFormatter,
    },
  ];

  useEffect(() => {
    getUsers();
    if (["superMario"].includes(userInfo.role)) {
      getOrganisations();
    }
  }, []);

  return (
    <Fragment>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={history.goBack}
          >
            <Icon path={mdiHome} size={1} />
          </button>
          <button
            className="btn btn-success btn-md"
            onClick={handleShowInsertUser}
          >
            Nieuw aanmaken
          </button>

          <button className="btn btn-md">
            <Icon path={mdiRefresh} size={1} onClick={getUsers} />
          </button>
        </div>
        <div className="row">
          <br />
        </div>
      </div>

      <BootstrapTable
        bootstrap4
        keyField="pkey"
        data={users}
        columns={userInfo.role === "superMario" ? columnsSuper : columns}
        bordered={false}
      />

      <Modal
        show={showVerifyDelete}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Gebruiker verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Weet je zeker dat je deze gebruiker wilt verwijderen?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success btn-sm" onClick={deleteUser}>
            Ja, verwijderen
          </Button>
          <Button variant="danger btn-sm" onClick={handleClose}>
            Annuleren
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUpdatePassword}
        onHide={handleCloseUpdatePassword}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Wachtwoord wijzigen</Modal.Title>
        </Modal.Header>
        <form
          autoComplete="off"
          onSubmit={(e, pkey) => onSubmitNewPassword(e, pkey)}
        >
          <Modal.Body>
            <div className="container">
              <div className="form-group row">
                <div className="col-12">Geef het nieuwe wachtwoord op</div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="updatePassword"
                  className="col-form-label col-4"
                >
                  Wachtwoord
                </label>
                <input
                  type="password"
                  name="updatePassword"
                  id="updatePassword"
                  className="form-control col-8"
                  autoComplete="off"
                  onChange={(e) => onChange(e)}
                  onClick={(e) => onChange(e)}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success btn-sm" type="submit">
              Wachtwoord invoeren
            </Button>
            <Button variant="danger btn-sm" onClick={handleCloseUpdatePassword}>
              Annuleren
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showInsertUser}
        onHide={handleCloseInsertUser}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header>
          <Modal.Title>Nieuwe gebruiker aanmaken</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={onSubmitForm}>
          <Modal.Body>
            <div className="container">
              <div className="form-group row">
                <div className="col-12">
                  Vul onderstaand formulier in om een nieuwe gebruiker aan te
                  maken
                </div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <label htmlFor="maleFemale" className="col-form-label col-4">
                    Aanhef
                  </label>
                  <select
                    value={newUser.sex}
                    name="newMaleFemale"
                    id="newMaleFemale"
                    className="col-8"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  >
                    <option value=""></option>
                    <option value="male">Dhr.</option>
                    <option value="female">Mevr.</option>
                  </select>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="newFirstName"
                    className="col-form-label col-4"
                  >
                    Voornaam
                  </label>
                  <input
                    type="text"
                    value={newUser.firstName}
                    name="newFirstName"
                    id="newFirstName"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newLastName" className="col-form-label col-4">
                    Achternaam
                  </label>
                  <input
                    type="text"
                    value={newUser.lastName}
                    name="newLastName"
                    id="newLastName"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newEmail" className="col-form-label col-4">
                    Email
                  </label>
                  <input
                    type="email"
                    value={newUser.email}
                    name="newEmail"
                    id="newEmail"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newUsername" className="col-form-label col-4">
                    Gebruikersnaam
                  </label>
                  <input
                    type="text"
                    value={newUser.username}
                    name="newUsername"
                    id="newUsername"
                    placeholder="AchternaamVoornaam"
                    className="form-control col-8"
                    autoComplete="on"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newPassword" className="col-form-label col-4">
                    Wachtwoord
                  </label>
                  <input
                    type="password"
                    value={newUser.password}
                    name="newPassword"
                    id="newPassword"
                    className="form-control col-8"
                    autoComplete="new-password"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newRole" className="col-form-label col-4">
                    Toegang
                  </label>
                  <select
                    value={newUser.role}
                    name="newRole"
                    id="newRole"
                    className="col-8"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  >
                    <option value="empty"></option>
                    <option value="user">Gebruiker</option>
                    <option value="admin">Administrator</option>
                    <option value="boa">BOA</option>
                  </select>
                </div>
                {["superMario"].includes(userInfo.role) && (
                  <>
                    <div className="form-group row">
                      <label htmlFor="newOrg" className="col-form-label col-4">
                        Organisatie
                      </label>
                      <select
                        value={newUser.org}
                        name="newOrganisation"
                        id="newOrganisation"
                        className="col-8"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                      >
                        <option value=""></option>
                        {organisations.map((organisation) => (
                          <option
                            value={
                              organisation.orgcode + "|" + organisation.pkey
                            }
                          >
                            {organisation.orgnaam}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success btn-sm" type="submit">
              Voeg gebruiker toe
            </Button>
            <Button variant="danger btn-sm" onClick={handleCloseInsertUser}>
              Annuleren
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showEditUser}
        onHide={handleCloseEditUser}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Bekijk en wijzig gebruiker</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={onSubmitEditForm}>
          <Modal.Body>
            <div className="container">
              <div className="form-group row">
                <div className="col-12">
                  Zie hieronder een overzicht van de bekende gegevens van deze
                  gebruiker
                </div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <label
                    htmlFor="editMaleFemale"
                    className="col-form-label col-4"
                  >
                    Aanhef
                  </label>
                  <select
                    value={editUser.sex}
                    name="editMaleFemale"
                    id="editMaleFemale"
                    className="col-8"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  >
                    <option value=""></option>
                    <option value="male">Dhr.</option>
                    <option value="female">Mevr.</option>
                  </select>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="editFirstName"
                    className="col-form-label col-4"
                  >
                    Voornaam
                  </label>
                  <input
                    type="text"
                    value={editUser.firstName}
                    name="editFirstName"
                    id="editFirstName"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="editLastName"
                    className="col-form-label col-4"
                  >
                    Achternaam
                  </label>
                  <input
                    type="text"
                    value={editUser.lastName}
                    name="editLastName"
                    id="editLastName"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="editEmail" className="col-form-label col-4">
                    Email
                  </label>
                  <input
                    type="email"
                    value={editUser.email}
                    name="editEmail"
                    id="editEmail"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="editUsername"
                    className="col-form-label col-4"
                  >
                    Gebruikersnaam
                  </label>
                  <input
                    type="text"
                    value={editUser.username}
                    name="editUsername"
                    id="editUsername"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>

                <div className="form-group row">
                  <label htmlFor="editRole" className="col-form-label col-4">
                    Toegang
                  </label>
                  <select
                    value={editUser.role}
                    name="editRole"
                    id="editRole"
                    className="col-8"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  >
                    <option value=""></option>
                    <option value="user">Gebruiker</option>
                    <option value="admin">Administrator</option>
                    <option value="boa">BOA</option>
                  </select>
                </div>
                <div className="form-group row">
                  <button
                    className="btn btn-outline-secondary btn-sm form-control col-12"
                    onClick={handleShowUpdatePassword}
                  >
                    Nieuw Wachtwoord Instellen
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success btn-sm" type="submit">
              Opslaan
            </Button>
            <Button variant="danger btn-sm" onClick={handleCloseEditUser}>
              Annuleren
            </Button>
          </Modal.Footer>
        </form>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Fragment>
  );
}
