import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHome } from "@mdi/js";
import "../dashboard.css";
import axios from "axios";

import { UserContext } from "../context/UserContext";

export default function DataDownload({ backendURL }) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [fromDate, setFromDate] = useState("");
  const [tillDate, setTillDate] = useState("");
  let history = useHistory();

  const onChange = (e) => {
    switch (e.target.name) {
      case "fromDate":
        setFromDate(e.target.value);
        break;
      case "tillDate":
        setTillDate(e.target.value);
        break;
      default:
        break;
    }
  };

  const downloadXLSFile = async () => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { "Content-Type": "blob", token: userContext.token };
    const config = {
      method: "GET",
      url: `${backendURL}data?fromdate=${fromDate}&tilldate=${tillDate}&type=excel`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);

      if (response.data.byteLength < 54) {
        alert("Geen rapporten gevonden binnen selectie");
      }

      if (response.data.byteLength > 54) {
        const outputFilename = `lhsassistent-${fromDate}-tm-${tillDate}.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={history.goBack}
          >
            <Icon path={mdiHome} size={1} />
          </button>
        </div>
        <div className="row">
          <br />
        </div>
        Geef de periode aan waarvan je de data wilt downloaden en druk op de
        knop downloaden om een Excel file te downloaden: <br />
        <div className="row">
          <div
            className="col-md-4"
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            <div htmlFor="fromDate">
              Start datum:{" "}
              <input
                name="fromDate"
                value={fromDate}
                style={{
                  border: "none",
                  cursor: "pointer",
                }}
                onChange={(e) => onChange(e)}
                type="date"
              />
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            <div htmlFor="tillDate">
              Eind datum:{" "}
              <input
                name="tillDate"
                value={tillDate}
                style={{
                  border: "none",
                  cursor: "pointer",
                }}
                onChange={(e) => onChange(e)}
                type="date"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col mt-5">
            <button onClick={downloadXLSFile}>Download</button>
          </div>
        </div>
      </div>
    </>
  );
}
