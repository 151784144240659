import React from "react";
import useNetwork from "../../hooks/useNetwork";
import NetworkInfotable from "./NetworkInfoTable";

const useNetworkExample = () => {
  const networkState = useNetwork();
  const {
    online,
    since,
    downLink,
    downLinkMax,
    effectiveType,
    rtt,
    saveData,
    type,
  } = networkState;

  return (
    <div>
      <div>
        {online ? <span>online</span> : <span>offline</span>}
        <div>
          <h1>Network Stats:</h1>
          <NetworkInfotable data={networkState} />
        </div>
      </div>
    </div>
  );
};

export default useNetworkExample;
