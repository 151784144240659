import React from "react";
import { useHistory } from "react-router-dom";

export default function ConfirmResetPassword() {
  const history = useHistory();
  return (
    <div className="row vertical-center">
      <div className="col-lg-6 offset-md-3 login-form">
        <h2 className="text-center my-5 login-title">Instructies verzonden</h2>
        <p className="m-5">
          Als je e-mailadres bij ons bekend is, hebben we je een e-mail gestuurd
          met daarin de instructie voor het instellen van een nieuw wachtwoord.
        </p>
        <p className="m-5">
          Let op! Het kan enkele minuten duren voordat je de e-mail ontvangt.
          Controleer bij twijfel ook even je spambox. Geen bericht ontvangen?
          Neem contact op met de beheerder van je organisatie.
        </p>
        <div className="m-5">
          <button
            className="btn btn-success btn-block btn-md"
            onClick={() => history.push({ pathname: "login" })}
          >
            Terug naar inloggen
          </button>
        </div>
      </div>
    </div>
  );
}
