import React, { useEffect, useState, useContext, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHome, mdiRefresh, mdiMagnifyExpand } from "@mdi/js";

import { UserContext } from "../context/UserContext";

export default function BoaFormList({ backendURL, userInfo }) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [users, setUsers] = useState([]);

  let history = useHistory();
  const goToHome = () => history.push("/");

  const goToBoaForm = (pkey) => {
    history.push({ pathname: "/boaform", search: `?reportid=${pkey}` });
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm mr-1"
          id={row.pkey}
          onClick={() => goToBoaForm(row.pkey)}
        >
          <Icon path={mdiMagnifyExpand} size={1} />
        </button>
      </>
    );
  };

  const getBoaForms = useCallback(async () => {
    if (userContext.token) {
      const response = await fetch(`${backendURL}forms/getboaforms`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      });

      const parseRes = await response.json();

      setUsers(parseRes);
    }
  }, [backendURL, setUsers, userContext.token]);

  useEffect(() => {
    getBoaForms();
  }, [userContext.details, getBoaForms]);

  // Datatable kolommen
  const columns = [
    {
      dataField: "datum",
      text: "Datum",
      sort: true,
    },
    {
      dataField: "zaaknummer",
      text: "Zaaknummer",
      sort: true,
    },
    {
      dataField: "scoreSort",
      text: "Score",
      sort: true,
      formatter: (cellContent) => {
        cellContent = cellContent.split("|");
        cellContent = cellContent[1];
        return <>{cellContent}</>;
      },
    },
    {
      dataField: "pkey",
      text: "MeldingsID",
      sort: true,
    },
    {
      dataField: "locatie",
      text: "Locatie",
      sort: true,
    },
    {
      dataField: "gemeente",
      text: "Gemeente",
      sort: true,
    },
    {
      dataField: "actions",
      text: "",
      csvExport: false,
      dummy: true,
      formatter: actionsFormatter,
    },
  ];

  return (
    <>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={goToHome}
          >
            <Icon path={mdiHome} size={1} />
          </button>

          <button className="btn btn-md">
            <Icon path={mdiRefresh} size={1} onClick={getBoaForms} />
          </button>
        </div>
        <div className="row">
          <br />
        </div>
      </div>

      <BootstrapTable
        bootstrap4
        keyField="pkey"
        data={users}
        columns={columns}
        bordered={false}
      />
    </>
  );
}
