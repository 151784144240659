import React, { Fragment, useState, useEffect, useCallback, useContext } from "react";
import "./App.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Offline, Online } from "react-detect-offline";

import { Switch, Route, Redirect, useLocation } from "react-router-dom";

// Constants
import { config } from "./Constants";

// hooks
import useNetwork from "./hooks/useNetwork";

// context
import { UserContext } from "./context/UserContext";

//components
import Header from "./components/header";
import Home from "./features/home";
import Login from "./features/login";
import MainForm from "./components/MainForm";
import Newform from "./components/Newform";
import BoaForm from "./components/BoaForm";
import BoaFormList from "./components/BoaFormList";
import MaintenanceReportList from "./components/MaintenanceReportList";
import Users from "./components/Users";
import Gemeenten from "./components/Gemeenten.js";
import Organisations from "./components/Organisations";
import Multipliers from "./components/Multipliers";
import Dashboard from "./components/Dashboard";
import DataDownload from "./components/DataDownload";
import Settings from "./components/Settings";
import ScrollToTop from "./components/ScrollToTop";

// features
import NetworkExample from "./features/status/NetworkExample";
import LostPassword from "./features/lostpassword";
import ResetPassword from "./features/resetpassword";
import ConfirmResetPassword from "./features/confirmResetPassword";

toast.configure();

const backendURL = config.url.backendUrl;

function App() {
  // Bij het navigeren door de app checken we of er updates beschikbaar zijn in de serviceworkers
  const networkState = useNetwork();
  const location = useLocation();
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);
  const [unsentReports, setUnsentReports] = useState(false);

  React.useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
        registrationsArray[0].update();
        setWaitingServiceWorker(registrationsArray[0].waiting);
      });
      let existingReports = JSON.parse(localStorage.getItem("rapporten"));
      // bestaat er rapporten in localstorage?
      if (existingReports) {
        // is deze array gevuld?
        if (existingReports.length > 0) {
          setUnsentReports(true);
        }
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (waitingServiceWorker) {
      setUpdateAvailable(true);
    }
  }, [waitingServiceWorker, location]);

  const updateAssets = () => {
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    alert("Bijgewerkt naar de laatste versie.");
    window.location.reload();
  };

  const sendReports = async () => {
    //TODO Eerst checken of het token niet verlopen is, anders opnieuw in laten loggen.
    let reportsToSend = JSON.parse(localStorage.getItem("rapporten"));

    for (let i = 0; i < reportsToSend.length; i++) {
      try {
        const response = await fetch(`${backendURL}forms`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: userContext.token,
          },
          body: JSON.stringify(reportsToSend[i]),
        });

        if (response.status === 200) {
          alert(
            `Rapport indienden geslaagd.\nEmail verstuurd naar het bij ons bekende email adres`
          );

          reportsToSend.splice(i, 1);
          i--;
        } else {
          alert("Er is een fout opgetreden probeer het opnieuw");
        }
      } catch (error) {
        console.log(error);
      }
    }

    localStorage.setItem("rapporten", JSON.stringify(reportsToSend));
    alert(`Rapporten succesvol verzonden!`);
    setUnsentReports(false);
  };

  const [userContext, setUserContext] = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({});
  const [curDate, setCurDate] = useState("");
  const [boaItemCount, setBoaItemCount] = useState(0);

  const verifyUser = useCallback(async () => {
    try {
      const response = await fetch(`${backendURL}auth/refreshToken`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, token: data.token };
        });
      } else {
        setUserContext((oldValues) => {
          return { ...oldValues, token: null };
        });
      }
      setTimeout(verifyUser, 5 * 60 * 1000);
    } catch (error) {}
  }, [setUserContext]);

  useEffect(() => {
    verifyUser();
  }, []);

  function getCurrentDate() {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    setCurDate(year + "-" + month + "-" + date);
  }

  async function getUserInfo() {
    try {
      const response = await fetch(backendURL + "user", {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setUserInfo({
        ...userInfo,
        userId: parseRes.userId,
        organisation: parseRes.organisation,
        organisationName: parseRes.organisationName,
        organisationId: parseRes.organisationId,
        role: parseRes.role,
        firstName: parseRes.firstName,
        lastName: parseRes.lastName,
        znomask: parseRes.znomask,
        znoplaceholder: parseRes.znoplaceholder,
      });

      if (parseRes.role === "boa") {
        const boaCount = await fetch(`${backendURL}forms/getboaformcount`, {
          method: "GET",
          headers: { token: userContext.token },
        });

        let boaItemCount = await boaCount.json();

        boaItemCount = boaItemCount[0].count;

        setBoaItemCount(boaItemCount);
      }
    } catch (err) {
      let userInfo = localStorage.getItem("userinfo");
      setUserInfo(JSON.parse(userInfo));
    }
  }

  /*
  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
  };

  async function isAuth() {
    try {
      const response = await fetch(backendURL + "auth/is-verify", {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      parseRes === true ? setIsAuthenticated(true) : setIsAuthenticated(false);
    } catch (err) {
      console.error(err.message);
    }
  }
  

  useEffect(() => {
    getUserInfo();
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
  */

  useEffect(() => {
    getCurrentDate();
    if (networkState.online === true) {
      console.log(`Online, authenticatie checken..`);
    } else {
      console.log(`Offline, we gebruiken de lokaal opgeslagen gegevens.`);
    }
  }, [networkState]);

  return (
    <Fragment>
      <Header />
      <div className="container">
        {isUpdateAvailable && (
          <Online>
            <div className="bg-primary fixed-top">
              <div className="row d-flex">
                <div className="col-sm-12 my-2 d-flex justify-content-center">
                  <div>
                    <span className="text-light pr-5">Update beschikbaar!</span>
                    <button type="button" className="btn btn-light btn-sm" onClick={updateAssets}>
                      Klik om te updaten
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Online>
        )}

        {unsentReports && (
          <Online>
            <div className="bg-primary fixed-top">
              <div className="row d-flex justify-content-center mt-2">
                <div className="col-xs-12">
                  <span className="text-light">Er zijn nog niet verzonden rapporten</span>
                </div>
              </div>
              <div className="row d-flex justify-content-center mb-2">
                <div className="col-xs-12">
                  <button type="button" className="btn btn-light btn-sm" onClick={sendReports}>
                    Verzenden
                  </button>
                </div>
              </div>
            </div>
          </Online>
        )}

        <div>
          <Offline>
            <div className="bg-warning sticky-top">
              <div className="row d-flex justify-content-center my-2">
                <div className="col-xs-12">
                  <span>
                    <i>Je werkt momenteel offline.</i>
                  </span>
                </div>
                <div className="col-xs-12">
                  <span>
                    <i>Rapportages worden lokaal opgeslagen.</i>
                  </span>
                </div>
              </div>
            </div>
          </Offline>
        </div>

        <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/form"
            render={(props) =>
              userContext.token ? (
                <MainForm
                  {...props}
                  /*setAuth={setAuth}*/
                  backendURL={backendURL}
                  userInfo={userInfo}
                  curDate={curDate}
                  setUpdateAvailable={setUpdateAvailable}
                  setUnsentReports={setUnsentReports}
                  waitingServiceWorker={waitingServiceWorker}
                  setWaitingServiceWorker={setWaitingServiceWorker}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/newform"
            render={(props) =>
              userContext.token ? (
                <Newform {...props} /*setAuth={setAuth}*/ />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/users"
            render={(props) =>
              userContext.token ? (
                <Users {...props} backendURL={backendURL} userInfo={userInfo} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/dashboard"
            render={(props) =>
              userContext.token ? (
                <Dashboard
                  {...props}
                  /*setAuth={setAuth}*/
                  backendURL={backendURL}
                  userInfo={userInfo}
                  curDate={curDate}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/download"
            render={(props) =>
              userContext.token ? (
                <DataDownload
                  {...props}
                  /*setAuth={setAuth}*/
                  backendURL={backendURL}
                  userInfo={userInfo}
                  curDate={curDate}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/organisaties"
            render={(props) =>
              userContext.token ? (
                <Organisations
                  {...props}
                  /*setAuth={setAuth}*/
                  backendURL={backendURL}
                  userInfo={userInfo}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/gemeenten"
            render={(props) =>
              userContext.token ? (
                <Gemeenten
                  {...props}
                  /*setAuth={setAuth}*/
                  backendURL={backendURL}
                  userInfo={userInfo}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/wegingsfactoren"
            render={(props) =>
              userContext.token ? (
                <Multipliers
                  {...props}
                  /*setAuth={setAuth}*/
                  backendURL={backendURL}
                  userInfo={userInfo}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/settings"
            render={(props) =>
              userContext.token ? (
                <Settings
                  {...props}
                  /*setAuth={setAuth}*/
                  backendURL={backendURL}
                  userInfo={userInfo}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/"
            render={(props) =>
              userContext.token ? (
                <Home
                  {...props}
                  backendURL={backendURL}
                  userInfo={userInfo}
                  boaItemCount={boaItemCount}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path="/login"
            render={(props) =>
              !userContext.token ? (
                <Login
                  {...props}
                  /*setAuth={setAuth}*/
                  backendURL={backendURL}
                  setUpdateAvailable={setUpdateAvailable}
                  waitingServiceWorker={waitingServiceWorker}
                  setWaitingServiceWorker={setWaitingServiceWorker}
                />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/boaformlist"
            render={(props) => (
              <BoaFormList
                {...props}
                /*setAuth={setAuth}*/
                backendURL={backendURL}
                userInfo={userInfo}
              />
            )}
          />
          <Route
            exact
            path="/maintenancereportlist"
            render={(props) => (
              <MaintenanceReportList
                {...props}
                /*setAuth={setAuth}*/
                backendURL={backendURL}
                userInfo={userInfo}
              />
            )}
          />
          <Route
            exact
            path="/boaform"
            render={(props) => (
              <BoaForm
                {...props}
                /*setAuth={setAuth}*/
                backendURL={backendURL}
                userInfo={userInfo}
              />
            )}
          />
          <Route
            exact
            path="/network"
            render={(props) => (
              <NetworkExample
                {...props}
                /*setAuth={setAuth}*/
                backendURL={backendURL}
                userInfo={userInfo}
              />
            )}
          />
          <Route
            exact
            path="/password-lost"
            render={(props) => (
              <LostPassword
                {...props}
                /*setAuth={setAuth}*/
                backendURL={backendURL}
                userInfo={userInfo}
              />
            )}
          />
          <Route
            exact
            path="/password-reset"
            render={(props) => (
              <ResetPassword
                {...props}
                /*setAuth={setAuth}*/
                backendURL={backendURL}
                userInfo={userInfo}
              />
            )}
          />
          <Route exact path="/confirm-reset-password" render={() => <ConfirmResetPassword />} />
        </Switch>
      </div>
    </Fragment>
  );
}

export default App;
