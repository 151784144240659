import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorkerRegistration";
import { UserProvider } from "./context/UserContext";

import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <UserProvider>
    <Router>
      <App />
    </Router>
  </UserProvider>,
  document.getElementById("root")
);

serviceWorker.register();
