import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHome } from "@mdi/js";
import { UserContext } from "../context/UserContext";

export default function Settings(userInfo) {
  let history = useHistory();

  const [userContext, setUserContext] = useContext(UserContext);

  const goToOrganisaties = () => history.push("/organisaties");
  const goToMultipliers = () => history.push("/wegingsfactoren");
  const goToGemeenten = () => history.push("/gemeenten");

  return (
    <Fragment>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={history.goBack}
          >
            <Icon path={mdiHome} size={1} />
          </button>
        </div>
        <div className="col-md-10 offset-md-1 my-2">
          {["superMario"].includes(userContext.details?.role) && (
            <>
              <div className="nav-light mt-3">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-block btn-lg"
                  onClick={goToOrganisaties}
                >
                  Organisaties
                </button>
              </div>
              <div className="nav-light mt-3">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-block btn-lg"
                  onClick={goToMultipliers}
                >
                  (Afwijkende) wegingsfactoren
                </button>
              </div>
            </>
          )}
          <div className="nav-light mt-3">
            <button
              type="button"
              className="btn btn-outline-primary btn-block btn-lg"
              onClick={goToGemeenten}
            >
              Gemeenten
            </button>
          </div>
          <br />
        </div>
      </div>
    </Fragment>
  );
}
