import React, { Fragment, useEffect, useState, useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icon from "@mdi/react";
import { mdiPencil, mdiDelete, mdiKeyboardBackspace, mdiRefresh } from "@mdi/js";
import { toast } from "react-toastify";
import { UserContext } from "../context/UserContext";

export default function Organisations({ setAuth, backendURL, userInfo }) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [organisations, setOrganisations] = useState([]);
  const [multiplierCodes, setMultiplierCodes] = useState([]);
  const [showVerifyDelete, setShowVerifyDelete] = useState(false);
  const [showInsertOrganisation, setShowInsertOrganisation] = useState(false);
  const [showEditOrganisation, setShowEditOrganisation] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState("");
  const [newOrganisation, setNewOrganisation] = useState({
    orgnaam: "",
    orgcode: "",
    multipliercode: "default",
    contact: "",
    contactmail: "",
    boamail: "",
  });
  const [editOrganisation, setEditOrganisation] = useState({
    orgnaam: "",
    orgcode: "",
    multipliercode: "default",
    contact: "",
    contactmail: "",
    boamail: "",
  });

  const handleClose = () => setShowVerifyDelete(false);
  const handleShowDeleteOrganisation = (e, pkey) => {
    setShowVerifyDelete(true);
    setSelectedOrganisation(pkey);
  };

  const handleShowInsertOrganisation = () => setShowInsertOrganisation(true);
  const handleCloseInsertOrganisation = () => {
    setShowInsertOrganisation(false);
    setNewOrganisation({ orgnaam: "", orgcode: "", multipliercode: "default" });
  };

  const handleShowEditOrganisation = async (e, pkey) => {
    setShowEditOrganisation(true);
    setSelectedOrganisation(pkey);

    const response = await fetch(`${backendURL}supermario/getorganisation/${pkey}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
    });

    const parseRes = await response.json();

    setEditOrganisation({
      pkey: parseRes[0].pkey,
      orgnaam: parseRes[0].orgnaam,
      orgcode: parseRes[0].orgcode,
      multipliercode: parseRes[0].multipliercode,
      contact: parseRes[0].contactpersoon,
      contactmail: parseRes[0].contactmail,
      boamail: parseRes[0].boamail,
    });
  };

  const handleCloseEditOrganisation = () => {
    setShowEditOrganisation(false);
    setEditOrganisation("");
  };

  const deleteOrganisation = async () => {
    const response = await fetch(`${backendURL}supermario/deleteorganisation`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
      body: JSON.stringify({ pkey: selectedOrganisation }),
    });

    const parseRes = await response.json();

    if (response.status === 200) {
      handleClose();
      getOrganisations();
      toast.success(parseRes.message);
    } else {
      toast.error(parseRes.message);
    }
  };

  let history = useHistory();

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm mr-1"
          onClick={(e) => handleShowEditOrganisation(e, row.pkey)}
          id={row.pkey}
        >
          <Icon path={mdiPencil} size={1} />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={(e) => handleShowDeleteOrganisation(e, row.pkey)}
        >
          <Icon path={mdiDelete} size={1} id={row.pkey} />
        </button>
      </>
    );
  };

  // Organisaties ophalen
  async function getOrganisations() {
    try {
      const response = await fetch(`${backendURL}superMario/getorganisations`, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setOrganisations(parseRes);
    } catch (error) {}
  }

  // Formulier versturen nieuwe organisatie
  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendURL}supermario/createorganisation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify(newOrganisation),
      });

      const parseRes = await response.json();

      if (response.status === 200) {
        handleCloseInsertOrganisation();
        getOrganisations();
        setNewOrganisation("");
        toast.success(parseRes.message);
      } else {
        toast.error(parseRes.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Formulier input controleren
  const onChange = (e) => {
    switch (e.target.id) {
      case "newOrganisation":
        setNewOrganisation({
          ...newOrganisation,
          orgnaam: e.target.value,
        });
        break;
      case "newOrganisationCode":
        setNewOrganisation({
          ...newOrganisation,
          orgcode: e.target.value,
        });
        break;
      case "newOrganisationMultipliercode":
        setNewOrganisation({
          ...newOrganisation,
          multipliercode: e.target.value,
        });
        break;
      case "newOrganisationContact":
        setNewOrganisation({
          ...newOrganisation,
          contact: e.target.value,
        });
        break;
      case "newOrganisationContactmail":
        setNewOrganisation({
          ...newOrganisation,
          contactmail: e.target.value,
        });
        break;
      case "newOrganisationBoamail":
        setNewOrganisation({
          ...newOrganisation,
          boamail: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  // Formulier versturen
  const onSubmitEditForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendURL}supermario/updateorganisation`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify(editOrganisation),
      });

      const parseRes = await response.json();

      if (response.status === 200) {
        handleCloseEditOrganisation();
        getOrganisations();
        setEditOrganisation("");
        toast.success(parseRes.message);
      } else {
        toast.error(parseRes.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Formulier input controleren
  const onChangeEdit = (e) => {
    switch (e.target.id) {
      case "editOrganisation":
        setEditOrganisation({
          ...editOrganisation,
          orgnaam: e.target.value,
        });
        break;
      case "editOrganisationCode":
        setEditOrganisation({
          ...editOrganisation,
          orgcode: e.target.value,
        });
        break;
      case "editMultiplierCode":
        setEditOrganisation({
          ...editOrganisation,
          multipliercode: e.target.value,
        });
        break;
      case "editContact":
        setEditOrganisation({
          ...editOrganisation,
          contact: e.target.value,
        });
        break;
      case "editContactmail":
        setEditOrganisation({
          ...editOrganisation,
          contactmail: e.target.value,
        });
        break;
      case "editBoamail":
        setEditOrganisation({
          ...editOrganisation,
          boamail: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  // MultiplierCodes Ophalen
  async function getMultiplierCodes() {
    try {
      const response = await fetch(`${backendURL}supermario/getmultipliercodes`, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setMultiplierCodes(parseRes);
    } catch (error) {}
  }

  // Datatable kolommen
  const columns = [
    {
      dataField: "orgcode",
      text: "Code",
      sort: true,
    },
    {
      dataField: "orgnaam",
      text: "Organisatie",
      sort: true,
    },
    {
      dataField: "multipliercode",
      text: "Multipliercode",
      sort: true,
    },
    {
      dataField: "actions",
      text: "",
      csvExport: false,
      dummy: true,
      formatter: actionsFormatter,
    },
  ];

  useEffect(() => {
    getOrganisations();
    getMultiplierCodes();
  }, []);

  return (
    <Fragment>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button className="btn btn-outline-secondary btn-md" onClick={history.goBack}>
            <Icon path={mdiKeyboardBackspace} size={1} />
          </button>
          <button className="btn btn-success btn-md" onClick={handleShowInsertOrganisation}>
            Nieuw aanmaken
          </button>

          <button className="btn btn-md">
            <Icon path={mdiRefresh} size={1} onClick={getOrganisations} />
          </button>
        </div>
        <div className="row">
          <br />
        </div>
      </div>

      <BootstrapTable
        bootstrap4
        keyField="pkey"
        data={organisations}
        columns={columns}
        bordered={false}
      />

      <Modal show={showVerifyDelete} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Organisatie verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>Weet je zeker dat je deze organisatie wilt verwijderen?</Modal.Body>
        <Modal.Footer>
          <Button variant="success btn-sm" onClick={deleteOrganisation}>
            Ja, verwijderen
          </Button>
          <Button variant="danger btn-sm" onClick={handleClose}>
            Annuleren
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showInsertOrganisation}
        onHide={handleCloseInsertOrganisation}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header>
          <Modal.Title>Nieuwe organisatie aanmaken</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={onSubmitForm}>
          <Modal.Body>
            <div className="container">
              <div className="form-group row">
                <div className="col-12">
                  Vul onderstaand formulier in om een nieuwe organisatie aan te maken
                </div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <label htmlFor="newOrganisation" className="col-form-label col-4">
                    Naam
                  </label>
                  <input
                    type="text"
                    value={newOrganisation.orgnaam}
                    name="newOrganisation"
                    id="newOrganisation"
                    className="form-control col-8"
                    autocomplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newOrganisationCode" className="col-form-label col-4">
                    Code
                  </label>
                  <input
                    type="text"
                    value={newOrganisation.orgcode}
                    name="newOrganisationCode"
                    id="newOrganisationCode"
                    className="form-control col-8"
                    autocomplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newOrganisationMultipliercode" className="col-form-label col-4">
                    Multipliercode
                  </label>
                  <select
                    value={newOrganisation.multipliercode}
                    name="newOrganisationMultipliercode"
                    id="newOrganisationMultipliercode"
                    className="col-8"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  >
                    <option value=""></option>
                    {multiplierCodes.map((multipliercode) => (
                      <option value={multipliercode.multipliercode}>
                        {multipliercode.multipliercode}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group row">
                  <label htmlFor="newOrganisationContact" className="col-form-label col-4">
                    Contactpersoon
                  </label>
                  <input
                    type="text"
                    value={newOrganisation.contact}
                    name="newOrganisationContact"
                    id="newOrganisationContact"
                    className="form-control col-8"
                    autocomplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newOrganisationContactmail" className="col-form-label col-4">
                    E-mail
                  </label>
                  <input
                    type="text"
                    value={newOrganisation.contactmail}
                    name="newOrganisationContactmail"
                    id="newOrganisationContactmail"
                    className="form-control col-8"
                    autocomplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="newOrganisationBoamail" className="col-form-label col-4">
                    BOA E-mail
                  </label>
                  <input
                    type="text"
                    value={newOrganisation.boamail}
                    name="newOrganisationBoamail"
                    id="newOrganisationBoamail"
                    className="form-control col-8"
                    autocomplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success btn-sm" type="submit">
              Voeg organisatie toe
            </Button>
            <Button variant="danger btn-sm" onClick={handleCloseInsertOrganisation}>
              Annuleren
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showEditOrganisation}
        onHide={handleCloseEditOrganisation}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Bekijk en wijzig organisatie</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={onSubmitEditForm}>
          <Modal.Body>
            <div className="container">
              <div className="form-group row">
                <div className="col-12">
                  Zie hieronder een overzicht van de bekende gegevens van deze organisatie
                </div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <label htmlFor="editOrganisation" className="col-form-label col-4">
                    Naam
                  </label>
                  <input
                    type="text"
                    value={editOrganisation.orgnaam}
                    name="editOrganisation"
                    id="editOrganisation"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="editOrganisationCode" className="col-form-label col-4">
                    Code
                  </label>
                  <input
                    type="text"
                    value={editOrganisation.orgcode}
                    name="editOrganisationCode"
                    id="editOrganisationCode"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="editMultiplierCode" className="col-form-label col-4">
                    Multipliercode
                  </label>
                  <select
                    value={editOrganisation.multipliercode}
                    name="editMultiplierCode"
                    id="editMultiplierCode"
                    className="col-8"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  >
                    <option value=""></option>
                    {multiplierCodes.map((multipliercode) => (
                      <option value={multipliercode.multipliercode}>
                        {multipliercode.multipliercode}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group row">
                  <label htmlFor="editContact" className="col-form-label col-4">
                    Contactpersoon
                  </label>
                  <input
                    type="text"
                    value={editOrganisation.contact}
                    name="editContact"
                    id="editContact"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="editContactmail" className="col-form-label col-4">
                    E-mail
                  </label>
                  <input
                    type="text"
                    value={editOrganisation.contactmail}
                    name="editContactmail"
                    id="editContactmail"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="editBoamail" className="col-form-label col-4">
                    BOA E-mail
                  </label>
                  <input
                    type="text"
                    value={editOrganisation.boamail}
                    name="editBoamail"
                    id="editBoamail"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success btn-sm" type="submit">
              Opslaan
            </Button>
            <Button variant="danger btn-sm" onClick={handleCloseEditOrganisation}>
              Annuleren
            </Button>
          </Modal.Footer>
        </form>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Fragment>
  );
}
