import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHome } from "@mdi/js";
import { Online } from "react-detect-offline";

import { UserContext } from "./../context/UserContext";

export default function Newform({ backendURL }) {
  const [userContext, setUserContext] = useContext(UserContext);

  let history = useHistory();

  const logoutHandler = async () => {
    try {
      const response = await fetch(`${backendURL}auth/logout`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
      });

      if (response.ok) {
        setUserContext((oldValues) => {
          return { ...oldValues, details: undefined, token: null };
        });
        window.localStorage.setItem("logout", Date.now());
      }
    } catch (error) {}
  };

  const queryParams = new URLSearchParams(window.location.search);
  const queryLocatie = queryParams.get("locatie");
  const queryGemeente = queryParams.get("gemeente");
  const queryZaaknummer = queryParams.get("zaaknummer");

  const newEmptyForm = () => history.push("/form");
  const newFormWithLocation = () =>
    history.push({
      pathname: "/form",
      search: `?locatie=${queryLocatie}&gemeente=${queryGemeente}&zaaknummer=${queryZaaknummer}`,
    });

  function goHome() {
    history.push("/");
  }

  return (
    <Fragment>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={() => goHome()}
          >
            <Icon path={mdiHome} size={1} />
          </button>
        </div>
        <div className="col-md-10 offset-md-1 my-2">
          <div className="nav-light mt-3">
            <button
              type="button"
              className="btn btn-outline-primary btn-block btn-lg"
              onClick={newFormWithLocation}
            >
              Nog een melding doen <b>zelfde</b> locatie
            </button>
          </div>
          <div className="nav-light mt-3">
            <button
              type="button"
              className="btn btn-outline-primary btn-block btn-lg"
              onClick={newEmptyForm}
            >
              Nog een melding doen <b>andere</b> locatie
            </button>
          </div>
          <Online>
            <div className="nav-light mt-3">
              <button
                className="btn btn-outline-danger btn-block btn-lg"
                onClick={logoutHandler}
              >
                Uitloggen
              </button>
            </div>
          </Online>
          <br />
        </div>
      </div>
    </Fragment>
  );
}
