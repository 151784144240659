import React, { useState, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Icon from "@mdi/react";

import { mdiMicrosoft } from "@mdi/js";

const Login = ({ backendURL }) => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [userContext, setUserContext] = useContext(UserContext);

  const { username, password } = inputs;

  const azureLogin = () => {
    window.open(`${backendURL}auth/login/azure`, "_self");
  };

  const onchange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const body = { username, password };

      const response = await fetch(`${backendURL}auth/login`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        if (response.status === 400) {
          toast.error("Vul alle velden in", { containerId: "login_page" });
        } else if (response.status === 401) {
          toast.error("Geen geldige inlog gegevens", {
            containerId: "login_page",
          });
        } else {
          toast.error("Er ging iets mis, probeer het later opnieuw", {
            containerId: "login_page",
          });
        }
      } else {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, token: data };
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={"login_page"}
        position={toast.POSITION.BOTTOM_LEFT}
      />
      <div className="row vertical-center">
        <div className="col-lg-6 offset-md-3 login-form">
          <h2 className="text-center my-5 login-title">Login</h2>

          <form onSubmit={onSubmitForm}>
            <input
              type="username"
              name="username"
              placeholder="gebruikersnaam"
              className="form-control my-3"
              value={username}
              onChange={(e) => onchange(e)}
            />
            <input
              type="password"
              name="password"
              placeholder="password"
              autoComplete="password"
              className="form-control my-3"
              value={password}
              onChange={(e) => onchange(e)}
            />
            <div
              className={
                username === ""
                  ? "btn-bar nav-light"
                  : password === ""
                  ? "btn-bar nav-light"
                  : ""
              }
            >
              <button className="btn btn-success btn-block btn-md mb-3 mt-3">
                Inloggen
              </button>

              <div className="text-center mb-4 mt-3">
                <Link to="/password-lost">Wachtwoord vergeten?</Link>
              </div>
            </div>
          </form>

          <div>
            <hr></hr>
          </div>

          <div>
            <button
              className="btn btn-secondary btn-block btn-sm mb-3 mt-3 px-5"
              onClick={azureLogin}
            >
              <Icon path={mdiMicrosoft} size={1} />
              <span className="pl-3">Login met Azure AD</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
