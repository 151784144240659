const prod = {
  url: {
    backendUrl: "https://api.lhsassistent.nl/",
  },
};

const dev = {
  url: {
    backendUrl: "http://localhost:8081/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
