import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icon from "@mdi/react";
import { mdiPencil, mdiDelete, mdiHome, mdiRefresh } from "@mdi/js";
import { toast } from "react-toastify";

import { UserContext } from "../context/UserContext";

export default function Gemeenten({ backendURL, userInfo }) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [gemeenten, setGemeenten] = useState([]);
  const [organisations, setOrganisations] = useState(["test"]);
  const [showVerifyDelete, setShowVerifyDelete] = useState(false);
  const [showInsertGemeente, setShowInsertGemeente] = useState(false);
  const [showEditGemeente, setShowEditGemeente] = useState(false);
  const [selectedGemeente, setSelectedGemeente] = useState("");
  const [newGemeente, setNewGemeente] = useState({
    gemeente: "",
    organisationId: "",
  });
  const [editGemeente, setEditGemeente] = useState({
    gemeente: "",
    organisationId: "",
  });

  const handleClose = () => setShowVerifyDelete(false);
  const handleShowDeleteGemeente = (e, pkey) => {
    setShowVerifyDelete(true);
    setSelectedGemeente(pkey);
  };

  const handleShowInsertGemeente = () => setShowInsertGemeente(true);
  const handleCloseInsertGemeente = () => {
    setShowInsertGemeente(false);
    setNewGemeente("");
  };

  const handleShowEditGemeente = async (e, pkey) => {
    setShowEditGemeente(true);
    setSelectedGemeente(pkey);

    const response = await fetch(`${backendURL}admin/getgemeente/${pkey}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
    });

    const parseRes = await response.json();

    setEditGemeente({
      pkey: parseRes[0].pkey,
      gemeente: parseRes[0].gemeente,
      organisationId: parseRes[0].organisationid,
    });
  };

  const handleCloseEditGemeente = () => {
    setShowEditGemeente(false);
    setEditGemeente("");
  };

  const deleteGemeente = async () => {
    const response = await fetch(`${backendURL}admin/deletegemeente`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
      body: JSON.stringify({ pkey: selectedGemeente }),
    });

    const parseRes = await response.json();

    if (response.status === 200) {
      handleCloseInsertGemeente();
      handleClose();
      getGemeenten();
      toast.success(parseRes.message);
    } else {
      toast.error(parseRes.message);
    }
  };

  let history = useHistory();

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm mr-1"
          onClick={(e) => handleShowEditGemeente(e, row.pkey)}
          id={row.pkey}
        >
          <Icon path={mdiPencil} size={1} />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={(e) => handleShowDeleteGemeente(e, row.pkey)}
        >
          <Icon path={mdiDelete} size={1} id={row.pkey} />
        </button>
      </>
    );
  };

  // Gebruikers ophalen
  async function getGemeenten() {
    try {
      const response = await fetch(`${backendURL}admin/getgemeenten`, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setGemeenten(parseRes);
    } catch (error) {}
  }

  // Organisaties Ophalen
  async function getOrganisations() {
    try {
      // extra verificatie op rol superMario
      if (userInfo.role !== "superMario") {
        return;
      }

      const response = await fetch(`${backendURL}supermario/getorganisations`, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setOrganisations(parseRes);
    } catch (error) {}
  }

  // Formulier versturen nieuwe user
  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendURL}admin/creategemeente`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify(newGemeente),
      });

      const parseRes = await response.json();

      if (response.status === 200) {
        handleCloseInsertGemeente();
        getGemeenten();
        setNewGemeente("");
        toast.success(parseRes.message);
      } else {
        toast.error(parseRes.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Formulier input controleren
  const onChange = (e) => {
    switch (e.target.id) {
      case "newGemeente":
        setNewGemeente({
          ...newGemeente,
          gemeente: e.target.value,
        });
        break;
      case "newOrganisation":
        setNewGemeente({
          ...newGemeente,
          organisation: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  // Formulier versturen
  const onSubmitEditForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendURL}admin/updategemeente`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token: userContext.token,
        },
        body: JSON.stringify(editGemeente),
      });

      const parseRes = await response.json();

      if (response.status === 200) {
        handleCloseEditGemeente();
        getGemeenten();
        setEditGemeente("");
        toast.success(parseRes.message);
      } else {
        toast.error(parseRes.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Formulier input controleren
  const onChangeEdit = (e) => {
    switch (e.target.id) {
      case "editGemeente":
        setEditGemeente({
          ...editGemeente,
          gemeente: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  // Datatable kolommen
  const columns = [
    {
      dataField: "gemeente",
      text: "Gemeente",
      sort: true,
    },
    {
      dataField: "actions",
      text: "",
      csvExport: false,
      dummy: true,
      formatter: actionsFormatter,
    },
  ];

  const columnsSuper = [
    {
      dataField: "gemeente",
      text: "Gemeente",
      sort: true,
    },
    {
      dataField: "organisation",
      text: "Organisatie",
      sort: true,
    },
    {
      dataField: "actions",
      text: "",
      csvExport: false,
      dummy: true,
      formatter: actionsFormatter,
    },
  ];

  useEffect(() => {
    getGemeenten();
    if (["superMario"].includes(userInfo.role)) {
      getOrganisations();
    }
  }, []);

  return (
    <Fragment>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={history.goBack}
          >
            <Icon path={mdiHome} size={1} />
          </button>
          <button
            className="btn btn-success btn-md"
            onClick={handleShowInsertGemeente}
          >
            Gemeente Toevoegen
          </button>

          <button className="btn btn-md">
            <Icon path={mdiRefresh} size={1} onClick={getGemeenten} />
          </button>
        </div>
        <div className="row">
          <br />
        </div>
      </div>

      <BootstrapTable
        bootstrap4
        keyField="pkey"
        data={gemeenten}
        columns={userInfo.role === "superMario" ? columnsSuper : columns}
        bordered={false}
      />

      <Modal
        show={showVerifyDelete}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Gemeente verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Weet je zeker dat je deze gemeente wilt verwijderen?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success btn-sm" onClick={deleteGemeente}>
            Ja, verwijderen
          </Button>
          <Button variant="danger btn-sm" onClick={handleClose}>
            Annuleren
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showInsertGemeente}
        onHide={handleCloseInsertGemeente}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header>
          <Modal.Title>Gemeente toevoegen</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={onSubmitForm}>
          <Modal.Body>
            <div className="container">
              <div className="form-group row">
                <div className="col-12">Geef de naam van de gemeente op</div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <label htmlFor="newGemeente" className="col-form-label col-4">
                    Gemeente
                  </label>
                  <input
                    type="text"
                    value={newGemeente.gemeente}
                    name="newGemeente"
                    id="newGemeente"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => onChange(e)}
                  />
                </div>
                {["superMario"].includes(userInfo.role) && (
                  <>
                    <div className="form-group row">
                      <label htmlFor="newOrg" className="col-form-label col-4">
                        Organisatie
                      </label>
                      <select
                        value={newGemeente.organisation}
                        name="newOrganisation"
                        id="newOrganisation"
                        className="col-8"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => onChange(e)}
                      >
                        <option value=""></option>
                        {organisations.map((organisation) => (
                          <option
                            value={
                              organisation.orgcode + "|" + organisation.pkey
                            }
                          >
                            {organisation.orgnaam}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success btn-sm" type="submit">
              Voeg gemeente toe
            </Button>
            <Button variant="danger btn-sm" onClick={handleCloseInsertGemeente}>
              Annuleren
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showEditGemeente}
        onHide={handleCloseEditGemeente}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Gemeente aanpassen</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={onSubmitEditForm}>
          <Modal.Body>
            <div className="container">
              <div className="form-group row">
                <div className="col-12">
                  Voor de nieuwe naam van de gemeente in
                </div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <label
                    htmlFor="editGemeente"
                    className="col-form-label col-4"
                  >
                    Gemeente
                  </label>
                  <input
                    type="text"
                    value={editGemeente.gemeente}
                    name="editGemeente"
                    id="editGemeente"
                    className="form-control col-8"
                    autoComplete="off"
                    onChange={(e) => onChangeEdit(e)}
                    onClick={(e) => onChangeEdit(e)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success btn-sm" type="submit">
              Opslaan
            </Button>
            <Button variant="danger btn-sm" onClick={handleCloseEditGemeente}>
              Annuleren
            </Button>
          </Modal.Footer>
        </form>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Fragment>
  );
}
