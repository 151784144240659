import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import logo from "../../assets/img/lhs-assistent-logo.png";

import "./style.css";

const Header = () => {
  return (
    <>
      <Container fluid="md" className="color-nav">
        <Navbar variant="light">
          <Container>
            <Navbar.Brand>
              <img src={logo} width={"50rem"} alt="logo" />
            </Navbar.Brand>
            <Nav>
              <span className="text-light slogan-text">
                ...ontwikkeld samen met de ODRU
              </span>
            </Nav>
          </Container>
        </Navbar>
      </Container>
    </>
  );
};

export default Header;
