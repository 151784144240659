import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Icon from "@mdi/react";
import {
  mdiHome,
  mdiRefresh,
  mdiDelete,
  mdiReplay,
  mdiFileFind,
  mdiClose,
} from "@mdi/js";
import { toast } from "react-toastify";

import { UserContext } from "../context/UserContext";
import ModalForm from "./ModalForm";

export default function MaintenanceReportList({ backendURL }) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [showVerifyDelete, setShowVerifyDelete] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [showModalForm, setShowModalForm] = useState(false);
  const [selectedReportObj, setSelectedReportObj] = useState({});
  const [subSearchResult, setSubSearchResult] = useState([]);
  const searchFieldRef = useRef();
  const [query, setQuery] = useState("");

  let history = useHistory();
  const goToHome = () => history.push("/");

  const handleClose = () => setShowVerifyDelete(false);
  const handleShowDeleteReport = (e, pkey) => {
    setShowVerifyDelete(true);
    setSelectedReport(pkey);
  };

  async function filterByQuery(query) {
    try {
      //console.log(query);
      setQuery(query);
      const newReports = [...reports];

      if (!query || query.length === 0) {
        getBoaForms();
        setSubSearchResult([]);
        return;
      }

      query = query.toLowerCase();

      const finalResult = [];
      const finalSubResult = [];

      newReports.forEach((item) => {
        if (
          item.medewerker &&
          (item.medewerker.toLowerCase().indexOf(query) !== -1 ||
            item.medewerker.includes(query))
        ) {
          finalSubResult.push({ medewerker: item.medewerker, pkey: item.pkey });
        }

        if (
          item.locatie.toLowerCase().indexOf(query) !== -1 ||
          item.locatie.includes(query)
        ) {
          finalSubResult.push({ locatie: item.locatie, pkey: item.pkey });
        }

        if (
          item.gemeente &&
          (item.gemeente.toLowerCase().indexOf(query) !== -1 ||
            item.gemeente.includes(query))
        ) {
          finalSubResult.push({ gemeente: item.gemeente, pkey: item.pkey });
        }

        if (
          item.omschrijving.toLowerCase().indexOf(query) !== -1 ||
          item.omschrijving.includes(query)
        ) {
          finalSubResult.push({
            omschrijving: item.omschrijving,
            pkey: item.pkey,
          });
        }

        if (
          (item.medewerker &&
            item.medewerker.toLowerCase().indexOf(query) !== -1) ||
          (item.medewerker && item.medewerker.includes(query)) ||
          (item.locatie && item.locatie.toLowerCase().indexOf(query) !== -1) ||
          (item.locatie && item.locatie.includes(query)) ||
          (item.gemeente &&
            item.gemeente.toLowerCase().indexOf(query) !== -1) ||
          (item.gemeente && item.gemeente.includes(query)) ||
          (item.omschrijving &&
            item.omschrijving.toLowerCase().indexOf(query) !== -1) ||
          (item.omschrijving && item.omschrijving.includes(query))
        ) {
          finalResult.push(item);
        }
      });

      console.log(finalSubResult);
      setSubSearchResult(finalSubResult);
      setFilteredReports(finalResult);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteUser = async () => {
    const response = await fetch(`${backendURL}admin/deletereport`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
      body: JSON.stringify({ pkey: selectedReport }),
    });

    const parseRes = await response.json();

    if (response.status === 200) {
      //handleCloseInsertUser();
      handleClose();
      getBoaForms();
      toast.success(parseRes.message);
    } else {
      toast.error(parseRes.message);
      handleClose();
    }
  };

  const resendReport = async (e, pkey) => {
    const response = await fetch(`${backendURL}admin/resendreport/${pkey}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
    });

    const parseRes = await response.json();

    if (response.status === 200) {
      //handleCloseInsertUser();
      //handleClose();
      //getUsers();
      toast.success(parseRes.message);
    } else {
      toast.error(parseRes.message);
    }
  };

  const getReport = async (reportid) => {
    let report = await fetch(`${backendURL}forms/getreport/${reportid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: userContext.token,
      },
    });

    report = await report.json();
    report = report[0];

    setSelectedReport(reportid);
    setSelectedReportObj(report);
    setShowModalForm(true);

    //console.log(report);
  };

  const modalFormClose = () => {
    setShowModalForm(false);
  };

  const setFocusSearchField = () => {
    if (query.length > 0) {
      searchFieldRef.current.focus();
    }
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <OverlayTrigger
          key={row.pkey + "tooltip_resend"}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-${"top"}`}>Opnieuw verzenden</Tooltip>}
        >
          <button
            className="btn btn-outline-primary btn-sm mr-1"
            onClick={(e) => {
              e.currentTarget.blur();
              resendReport(e, row.pkey);
            }}
            id={row.pkey}
          >
            <Icon path={mdiReplay} size={1} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          key={row.pkey + "tooltip_verwijder"}
          placement={"top"}
          overlay={
            <Tooltip id={`tooltip-${"top"}`}>Rapport verwijderen</Tooltip>
          }
        >
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={(e) => {
              e.currentTarget.blur();
              handleShowDeleteReport(e, row.pkey);
            }}
          >
            <Icon path={mdiDelete} size={1} id={row.pkey} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          key={row.pkey + "tooltip_rapport"}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-${"top"}`}>Bekijk rapport</Tooltip>}
        >
          <button
            className="btn btn-outline-success btn-sm"
            onClick={(e) => {
              e.currentTarget.blur();
              getReport(row.pkey);
            }}
          >
            <Icon path={mdiFileFind} size={1} id={row.pkey} />
          </button>
        </OverlayTrigger>
      </>
    );
  };

  // Te beoordelen rapporten ophalen
  const getBoaForms = useCallback(async () => {
    if (userContext.token) {
      const response = await fetch(`${backendURL}forms/getallreports`, {
        method: "GET",
        headers: { token: userContext.token },
      });

      const parseRes = await response.json();

      setReports(parseRes);
      setFilteredReports(parseRes);
    }
  }, [backendURL, setReports, setFilteredReports, userContext.token]);

  useEffect(() => {
    getBoaForms();
  }, [userContext.details, getBoaForms]);

  // Datatable kolommen
  const columns = [
    {
      dataField: "pkey",
      text: "MeldingsID",
      sort: true,
    },
    {
      dataField: "medewerker",
      text: "Toezichthouder",
      sort: true,
    },
    {
      dataField: "gemeente",
      text: "Gemeente",
      sort: true,
    },
    {
      dataField: "actions",
      text: "",
      csvExport: false,
      dummy: true,
      formatter: actionsFormatter,
    },
  ];

  return (
    <>
      <div className="container mt-3">
        <div className="row justify-content-between">
          <button
            className="btn btn-outline-secondary btn-md"
            onClick={goToHome}
          >
            <Icon path={mdiHome} size={1} />
          </button>

          <div className="col-6">
            <input
              type="search"
              name="search"
              onChange={(e) => filterByQuery(e.target.value)}
              className="form-control"
              id="search"
              placeholder="Zoeken..."
              value={query}
              ref={searchFieldRef}
            ></input>
          </div>

          <button className="btn btn-md">
            <Icon path={mdiRefresh} size={1} onClick={getBoaForms} />
          </button>
        </div>
        <div className="row overflow-hidden">
          <div id="subresult" className="w-100 overflow-wrap">
            {subSearchResult.map((item, key) => {
              return (
                <button
                  key={key}
                  className="dropdown-item "
                  type="button"
                  onClick={() => getReport(item.pkey)}
                >
                  {Object.keys(item)[0]} : {Object.values(item)[0]} ({item.pkey}
                  )
                </button>
              );
            })}
          </div>
          <br />
        </div>
      </div>

      <BootstrapTable
        bootstrap4
        keyField="pkey"
        data={filteredReports}
        columns={columns}
        bordered={false}
        pagination={paginationFactory({
          sizePerPage: 50,
          alwaysShowAllBtns: true,
        })}
      />

      <Modal
        show={showModalForm}
        contentClassName="modal-90w"
        keyboard={true}
        onEscapeKeyDown={modalFormClose}
        restoreFocus={false}
        onExited={setFocusSearchField}
      >
        <Modal.Title>
          <div className="container pt-3">
            <div className="row justify-content-between">
              <div className="col-sm-8">
                Rapport MeldingsID {selectedReport}
              </div>
              <div className="col-1">
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={modalFormClose}
                >
                  <Icon path={mdiClose} size={1} />
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="p-2">
                <button
                  className="btn btn-outline-primary btn-sm mr-1"
                  onClick={(e) => {
                    e.currentTarget.blur();
                    resendReport(e, selectedReport);
                  }}
                >
                  <Icon path={mdiReplay} size={1} />
                  <span className="pl-2 font-weight-bold">
                    Opnieuw verzenden
                  </span>
                </button>
              </div>
              <div className="p-2">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={(e) => {
                    e.currentTarget.blur();
                    handleShowDeleteReport(e, selectedReport);
                  }}
                >
                  <Icon path={mdiDelete} size={1} id={selectedReport} />
                  <span className="pl-2 font-weight-bold">Verwijderen</span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Title>

        <Modal.Body>
          <ModalForm reportObj={selectedReportObj} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary btn-sm" onClick={modalFormClose}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showVerifyDelete}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Rapport verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Weet je zeker dat je dit rapport wilt verwijderen?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success btn-sm" onClick={deleteUser}>
            Ja, verwijderen
          </Button>
          <Button variant="danger btn-sm" onClick={handleClose}>
            Annuleren
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
